import React, { useRef, useEffect } from 'react'
import { useStyles } from "./styles";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';




import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';


import {get,post} from "./commons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*const convertBlobToBase64 = async (blob) => {
  return await blobToBase64(blob);
}*/

/*const blobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});*/

export default function CanvasSign ({returnCanvas,saveshiftdata,date,signatureID,resubmit,isCompleted}) {
  const classes = useStyles();
  const canvasRef = useRef(null);
  const positions = useRef({x:0,y:0});
  const recording = useRef(false);
  const [open, setOpen] = React.useState(true);
  const [signaturePic, setSignaturePic] = React.useState();

  useEffect(()=>{
    if (signatureID) {
      get(`/server/request/getsignature?id=${signatureID}`).then((response)=>{
        if (response.image) setSignaturePic(response.image);
      });
    }
  },[signatureID])

  const handleClickOpen = () => {
    document.body.style['touch-action'] = 'none'
  };

  const clearAll = () => {
    recording.current = false;
    setSignaturePic();
    returnCanvas2server();
    returnCanvas();
  };

  const returnCanvas2server = (val) => {
    post('/server/request/storesignature',{data:val,date:date}).then((response)=>{
      if (response.stored) returnCanvas(response.stored);
    });
  };

  const scaleImage = (url) => {
  	let img = new Image();
  	img.onload = function(){
  		var canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d");
          canvas.width = 200;
          canvas.height= 200;
          ctx.drawImage(this, 0, 0, 200, 200);
          let newPic = canvas.toDataURL();
          returnCanvas2server(newPic);
          canvas.toBlob(function(blob){
            let href = URL.createObjectURL(blob);
            setSignaturePic(href)
          },'image/jpeg',0.9);
  	};
  	img.src = url;
  }

  const handleSave = () => {
    recording.current = false;
    document.body.style.removeProperty('touch-action')
    const canvas = canvasRef.current;
    canvas.toBlob(function(blob){
      let href = URL.createObjectURL(blob);
      scaleImage(href);
    },'image/png');
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const drawing = (x,y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(positions.current.x,positions.current.y);
    ctx.lineTo(x, y);//console.log(positions.current.x,positions.current.y);
    ctx.stroke();/*
    var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var d = imgData.data;
    for (var i = 0; i < d.length; i += 4) {
      var med = (d[i] + d[i + 1] + d[i + 2]) / 3;
      d[i] = d[i + 1] = d[i + 2] = med;
    }
    ctx.putImageData(imgData, 0, 0);*/
    positions.current = {x:x,y:y};
  }
  const mouseDown = (event) => {
    recording.current = true;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    let top =  Math.round(canvas.getBoundingClientRect().top);
    let left =  Math.round(canvas.getBoundingClientRect().left);
    let pageX = event.pageX
    let clientY = event.clientY
    positions.current = {x:pageX-left,y:clientY-top}
  }
  const mousemov = (event) => {
    if (recording.current) {
      const canvas = canvasRef.current;
      let top =  Math.round(canvas.getBoundingClientRect().top);
      let left =  Math.round(canvas.getBoundingClientRect().left);
      let pageX = event.pageX
      let clientY = event.clientY
      drawing(pageX-left,clientY-top)
    }
  }
  const touchDown = (event) => {//console.log(document.body.style.overflow)
    recording.current = true;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    let clientX = Math.round(event.touches[0].clientX);
    let clientY = Math.round(event.touches[0].clientY);
    let top = Math.round(canvas.getBoundingClientRect().top);
    let left =  Math.round(canvas.getBoundingClientRect().left);
    //console.log("touchDown",clientX-left,clientY-top)
    positions.current = {x:clientX-left,y:clientY-top}
  }
  const touchmove = (event) => {
    if (recording.current) {
      const canvas = canvasRef.current;
      let clientX = Math.round(event.touches[0].clientX);
      let clientY = Math.round(event.touches[0].clientY);
      let top = Math.round(canvas.getBoundingClientRect().top);
      let left =  Math.round(canvas.getBoundingClientRect().left);
      drawing(clientX-left,clientY-top)
      //console.log(clientX,clientY,top,left,clientX-left,clientY-top)
    }
  }

/*  function toggleFullscreen() {
    let elem = document.querySelector("video");

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch(err => {
        alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  }*/

  return (
    <>
      {signaturePic &&
        <>
          <Grid item xs={12} alignItems="center" className={classes.center}>
            <img alt="" src={signaturePic} className={classes.borderblacksolid}/>
          </Grid>
        </>
      }
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Firmar el parte de trabajo
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              Guardar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.signbox}>
          <canvas
            width={300}
            height={300}
            ref={canvasRef}
            onMouseDown={mouseDown}
            onMouseMove={mousemov}
            onTouchStart={touchDown}
            onTouchMove={touchmove}
            className={classes.bordercanvas}/>
        </DialogContent>
      </Dialog>
    </>
  );
}
