import React, { useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DvrIcon from '@mui/icons-material/Dvr';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import BusinessIcon from '@mui/icons-material/Business';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import DoneIcon from '@mui/icons-material/Done';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TodayIcon from '@mui/icons-material/Today';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BadgeIcon from '@mui/icons-material/Badge';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ConstructionIcon from '@mui/icons-material/Construction';
import Menu from '@mui/material/Menu';
import PublishIcon from '@mui/icons-material/Publish';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AllInboxIcon from '@mui/icons-material/AllInbox';

import useWebSocket, { ReadyState } from "react-use-websocket";

import { useCookies } from "react-cookie";
import { phone_numbers } from "./countries";
import { useStyles } from "./styles";
import WorkReport from "./work_report";
import MyShifts from "./myshifts";
import AllShifts from "./allshifts";
import EmployeeData from "./employee_data";
import EmployeesData from "./employeesdata";
import ClientsReview from "./clients_review";
import EmployeesReview from "./employees_review";
import WorkOrdersReview from "./workorders_review";
import ProductionData from "./proddata_review";
import ProductionEditData from "./proddata_editt";
import ProductionMgmtData from "./proddata_management";
import AnalyticsData from "./analytics";
import Exports from "./exports";
import {get, post, logo, logo_icon} from "./commons";

export default function Login(props) {//console.log("***login***")
  const { pathbiz } = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  const classes = useStyles();
  const isMobile = (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) ? true : false;
  //const host = window.location.host.split('.');
  //const pathbiz = (host.length === 3 && host[0] !== "www") ? host[0] : (window.location.pathname.length > 1) ? window.location.pathname.substr(1) : null;
  if (pathbiz && cookies.biz !== pathbiz) setCookie('biz', pathbiz, { path: '/' });
  else if (props.redirectbiz && cookies.biz !== props.redirectbiz) setCookie('biz', props.redirectbiz, { path: '/' });
  const [isMobileSize,setIsMobileSize] = React.useState(true);
  const [usernameData,setUsernameData] = React.useState();
  const [passwordData,setPasswordData] = React.useState();
  const [mobileData, setMobileData] = React.useState("");
  const [codeData, setCodeData] = React.useState();
  const [login,setLogin] = React.useState(true);
  const [open,setOpen] = React.useState(false);
  const [toview,setToview] = React.useState("");
  const [viewSideMenu,setViewSideMenu] = React.useState(false);
  const [registerWith, setRegisterWith] = React.useState(0);
  const [keepsessionopen, setkeepsessionopen] = React.useState(isMobile);
  const [lostpass, setLostPass] = React.useState(false);
  const [newCode, setNewCode] = React.useState(false);
  const [newPass, setNewPass] = React.useState(false);
  const [unknownUser,setUnknownUser] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState("34");
  const [newPasswordData, setNewPasswordData] = React.useState({text1:"", text2:"", success1:false, success2:false});
  const [pendingItems, setPendingItems] = React.useState({mobile:false, code:false, username:false, password: false, recaptcha:false});
  const [userData, setUserData] = React.useState();
  const [pendingToken, setPendingToken] = React.useState(0);
  const newbiz = (pathbiz) ? pathbiz : (props.redirectbiz) ? props.redirectbiz : null;
  const [biz, setBiz] = React.useState(newbiz);
  const [allBiz, setAllBiz] = React.useState();
  const [managers, setManagers] = React.useState();
  const [projects, setProjects] = React.useState();
  const [allProjects, setAllProjects] = React.useState();
  const [rejectedWOs, setRejectedWOs] = React.useState(0);
  const [pendingWOs, setPendingWOs] = React.useState(0);
  const [editingDate, setEditingDate] = React.useState();
  const [privilegesModel, setPrivilegesModel] = React.useState();
  const [workflowsModel, setWorkflowsModel] = React.useState({leftmenu:[]});
  const [loginError, setLogingError] = React.useState(false);
  const [captchaNeeded, setCaptchaNeeded] = React.useState(true);
  const [anchorUserMenu, setAnchorUserMenu] = React.useState(null);
  const [anchorprodItemsMenu, setAnchorprodItemsMenu] = React.useState(null);
  const [globalVariables, setGlobalVariables] = React.useState({});
  const [ws_id, setws_id] = React.useState();
  const [ws_prod_change, set_ws_prod_change] = React.useState(false);
  const [ws_set_id, set_ws_set_id] = React.useState(false);
  const [proditemsWOs, setProditemsWOs] = React.useState([[],[]]);

  function isUserEvent(message) {
    const parsedMessage = JSON.parse(message.data);
    return parsedMessage.type === "userevent";
  }

  function isDocumentEvent(message) {
    const parsedMessage = JSON.parse(message.data);
    return parsedMessage.type === "contentchange";
  }

  /// ***
  const domainName = (window.location.host.indexOf("workload.agency") > -1) ? "workload.agency" : window.location.host;
  // window.location.host.split(".").slice(-(window.location.host.split(".").length === 4 ? 3 : 2)).join('.')
  const socketUrl = `wss://${domainName}/ws`;
  const [serverMessage, setServerMessage] = React.useState("");
  const setTimerRef = React.useRef();
  const [messageHistory, setMessageHistory] = React.useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    queryParams : { token: ws_id },
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 100,
    reconnectInterval: 3000
  },Boolean(ws_id));

  const handleClickSendMessage = React.useCallback(() => sendMessage('Hello'), []);
  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
      if (lastMessage.data?.substr(0,7) === "review:") {
        if (lastMessage.data?.substr(7) === "pendingitems") getWOitems();
        else if (lastMessage.data?.substr(7,9) === "proditems") {
          let items = lastMessage.data?.substr(17).split("///");
          let newfilters = (items[1]?.length>0) ? JSON.parse(`[${items[1]}]`) : [];
          let newwos = items[0]?.split(",");
          if (newfilters.length > 0 && newfilters.length === newwos.length) {
            let data_wo = [...proditemsWOs[0], ...newwos];
            let data_f = [...proditemsWOs[1], ...newfilters];
            let mixedset = data_wo.map((e,p)=>e.toString()+"-"+data_f[p].toString());
            let duplicates = []
            mixedset.forEach((val1,p1)=>{
              mixedset.forEach((val2,p2)=>{
                if (val1 === val2 && p1 !== p2 && p2 !== mixedset.indexOf(val1)) duplicates.push(p2)
              });
            });
            let data_wo_filtered = data_wo.filter((e,p)=>!duplicates.includes(p))
            let data_f_filtered = data_f.filter((e,p)=>!duplicates.includes(p));
            if (data_wo_filtered.length > 0 && data_f_filtered.length > 0) setProditemsWOs([[...proditemsWOs[0],...data_wo_filtered],[...proditemsWOs[1],...data_f_filtered]]);
            console.log([[...data_wo_filtered],[...data_f_filtered]]);
          }
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!ws_set_id && userData?.code) {
      sendMessage(`activate_b:${biz}`);
      sendMessage(`activate_u:${userData.code}`);
      if (userData.privileges?.dataproduction) sendMessage(`prod_user`);
      set_ws_set_id(true);
    }
    const timer = setInterval(() => {
      sendMessage('h')
    }, 60000);
      return () => clearTimeout(timer);
    }, [ws_id]);
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(()=>{
    if (connectionStatus && (connectionStatus === 'Closed')) {
      try {
        get('/server/wsreloged/').then((response)=>{
          if (response?.ws) {
            setws_id(response.ws);
            if (props.page2view!=="production_mgmt") informWSprod_mgmt(false);
            else informWSprod_mgmt(true);
            set_ws_set_id(false);
          }
        });
      }
      catch (e) {
        console.log(e);
      }
    }
  },[connectionStatus]);

  const informWSprod_mgmt = (active) => {
    if (active) sendMessage('prod_on');
    else sendMessage('prod_off');
  }

/// ****

  const openUserMenu = Boolean(anchorUserMenu);
  const openProdItemMenu = Boolean(anchorprodItemsMenu);
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/im;
  const phoneRegex = /^[0-9()+ ]*$/;
  const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  const recaptcha = '6LcMkncgAAAAALL396p5slDGQgX28tSZPxYR2FdF';
  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event.data.msg && userData && userData.code && event.data.msg.user === userData.code) {
     if (event.data.msg.rejected) setRejectedWOs(event.data.msg.rejected)
     else if (event.data.msg.pendingapproval) setPendingWOs(event.data.msg.pendingapproval)
    }
  });
  const icons1 = {
    DvrIcon:<DvrIcon/>,
    BusinessIcon:<BusinessIcon/>,
    PeopleAltIcon:<PeopleAltIcon/>,
    MenuBookIcon:<MenuBookIcon/>,
    RecentActorsIcon:<RecentActorsIcon/>,
    SupervisedUserCircleIcon:<SupervisedUserCircleIcon/>,
    AccountCircleIcon:<AccountCircleIcon/>,

    FolderOpenIcon:<FolderOpenIcon/>,
    EngineeringIcon:<EngineeringIcon/>,
    TodayIcon:<TodayIcon/>,
    EventAvailableIcon:<EventAvailableIcon/>,
    CalendarMonthIcon:<CalendarMonthIcon/>,
    BadgeIcon:<BadgeIcon/>,
    ContactPageIcon:<ContactPageIcon/>,
    FindInPageIcon:<FindInPageIcon/>,
    ListAltIcon:<ListAltIcon/>,
    ConstructionIcon:<ConstructionIcon/>,
    PublishIcon:<PublishIcon/>,
    QueryStatsIcon:<QueryStatsIcon/>,
    ArchitectureIcon:<ArchitectureIcon/>,
    EditNoteIcon:<EditNoteIcon/>
  };
  const changeGlobalVariables = (e,v) => {
    let newGlobVal = {...globalVariables};
    newGlobVal[e] = v;
    setGlobalVariables(newGlobVal); //console.log(newGlobVal)
  }
  const listDiv = () => {
    return (
    <div
      className={viewSideMenu ? classes.drawerContainer : classes.hidden }
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      >
      <Toolbar/>
      {userData &&
        <List>
          {workflowsModel.leftmenu.map((text, index) => (
            <div key={`div${index}`}>
              {(userData.active === 1 || text.inactive) && (text.roles.includes(userData.role) || (text.privileges && text.privileges.filter(element => userData.privileges && userData.privileges[element] && userData.privileges[element] === "true").length > 0)) &&
                <ListItem button key={`buttonListItem${index}`} onClick={()=>showPage(text.id)}>
                  <ListItemIcon key={`icon${index}`}>{icons1[text.icon]}</ListItemIcon>
                  {viewSideMenu &&
                    <ListItemText key={`text${index}`} primary={text.name} />
                  }
                </ListItem>
              }
            </div>
          ))}
        </List>
      }
    </div>
  )}
/*  const ButtonNew = () => {
    return (
      <Button
        variant="contained"
        size="small"
        className={classes.button}
        startIcon={<AddIcon />}
      > Nuevo </Button>
    )
  }*/
  function readyRecaptcha(id) {//console.log("rectp",id)
    window.grecaptcha.render('recaptcha', {
          'sitekey' : recaptcha,
          'theme' : 'light',
          'size': 'invisible'
        })
    window.grecaptcha.execute() //.then(function(token) { console.log("low",token) });
  }

  function loadRecaptcha() {//console.log("readyRecaptcha")
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (isScriptExist && callback) callback();
    }
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=explicit&hl=es`, function () {
      if (window.grecaptcha)
        window.grecaptcha.ready(() => readyRecaptcha(1));
    });
  }
  function getWOitems() {
    get('/server/request/pendingitems').then((response)=>{
      if (response.rejected) setRejectedWOs(response.rejected);
      else setRejectedWOs(0);
      if (response.pending) setPendingWOs(response.pending);
      else setPendingWOs(0);
    });
  }
  useEffect(()=> {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: 'REVIEW_SUBSCRIPTION'
      });
    }
    /*
    new Promise(function(resolve, reject) {
      navigator.serviceWorker.ready.then(serviceWorkerRegistration => {//console.log(serviceWorkerRegistration,navigator.serviceWorker.controller)
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array( applicationServerKey ),
        };
        serviceWorkerRegistration.pushManager.subscribe(subscribeOptions).then( pushSubscription => {
           db.open();
           db.transaction("rw", db.notifications, db.users, function () {
             db.notifications.put(
               { id: 1, data: JSON.parse(JSON.stringify(pushSubscription)) },
             )
           }).catch (function (e) {
              console.error(e.stack || e);
          });
        });
      });
    });
    */
  },[])
  const retrieveBizData = () => {
      get('/server/request/managingdata').then((response)=>{
        if (response.managers && response.projects) {
          setManagers(response.managers);
          setProjects(response.projects.filter(e=>e.status === 'open'));
          setAllProjects(response.projects);
          getWOitems();
        }
      });
  }
  const assignPage2view = (page,info1,info2) => {//console.log("assignPage2view",page,info1,info2)
    setEditingDate();
    if (!info1) navigate(`/${page}`);
    else if (!info2) navigate(`/${page}`, { state: { info: info1} });
    else navigate(`/${page}`, { state: { info: info1, info2: info2} });
    //setToview(page);
  }
  const assignPage2viewAndClear = (page,info1,info2) => {
    setAnchorprodItemsMenu(null);
    assignPage2view(page,info1,info2);
  }
  const getQualityDataStatus = () => {
    get('/server/request/pendingproddata').then((response2)=>{
      if (response2.data) {
        let data_wo_filtered = response2.data.map(e=>e.wo);
        let data_f_filtered = response2.data.map(e=>JSON.parse(e.filters));
        setProditemsWOs([[...data_wo_filtered],[...data_f_filtered]]);
        setTimeout(() => getQualityDataStatus(), 60000);
      }
    });
  }
  useEffect(() => {
    if (!login && !managers && !projects) retrieveBizData();
    if (login) {}
    if (!window.grecaptcha && login) {
      get('/server/isloged').then((response)=>{
        if (response.user && response.user.id && response.user.biz) {
          setLogin(false);
          setBiz(response.user.biz);
          if (response.privileges) setPrivilegesModel(response.privileges);
          if (response.workflows) setWorkflowsModel(response.workflows);
          let user_data = response.user;
          if (user_data.privileges) {
            user_data.privileges = JSON.parse(user_data.privileges);
            if (user_data.privileges?.qualitydata === "true") getQualityDataStatus();
          }
          setUserData(user_data);
          if ((!isNaN(user_data.active) && user_data.active === 0) || (response.workflows.employeedata === "required" && !response.user.employeedata)) assignPage2view("mydata");
          else if (response.user.role < 50) {
            if (props && props.page2view) assignPage2view(props.page2view)
            else assignPage2view("dailyshift");
          }
          else {
            if (props && props.page2view) assignPage2view(props.page2view)
            else assignPage2view("dailyshifts");
          }
          if (response.ws) setws_id(response.ws);
        }
        else {
          if (!biz) get(`/server/allbiz?biz=${biz}`).then((response)=>{
            if(response.all) {
              if (response.exists && response.exists === biz) setAllBiz();
              else if (response.all) {
                setAllBiz(response.all);
                }
              }
            });
          get('/server/needRecaptcha').then((response)=>{
            if (response.needed) loadRecaptcha();
            else setCaptchaNeeded(false);
          });
        }
      });
    }
  //  else if (window.grecaptcha && login) window.grecaptcha.ready(() => readyRecaptcha(2));
  }, [login,biz]);
  const toggleDrawer = () =>  {
    if (open) setOpen(false);
    else setOpen(true);
  };
  const showPage = (value) => {
    assignPage2view(value);
    setViewSideMenu(false);
  }
  const usernameChange = (event) => {
    setUsernameData(event.target.value);
  }
  const passChange = (event) => {
    setPasswordData(event.target.value);
  }
  const newPassChange1 = (event) => {
    let newval = event.target.value;
    let newpas = {...newPasswordData, text1:newval}
    if (passRegex.test(newval)) newpas.success1 = true
    setNewPasswordData({...newpas});
  }
  const newPassChange2 = (event) => {
    let newval = event.target.value;
    let newpas = {...newPasswordData, text2:newval}
    if (passRegex.test(newval) && newval === newPasswordData.text1) newpas.success2 = true
    setNewPasswordData({...newpas});
  }
  const mobileChange = (event) => {
    setMobileData(event.target.value);
  }
  const codeChange = (event) => {
    setCodeData(event.target.value);
  }
  function grecaptchaRestart() {
    if (window.grecaptcha) {
      window.grecaptcha.reset();
      if (window.grecaptcha) window.grecaptcha.execute();
      else loadRecaptcha();
    }
  }
  const checkWidth = () => {
    const match = window.matchMedia(`(max-width: 650px)`);
    if (match.matches) setIsMobileSize(true);
    else setIsMobileSize(false);
  };
  useEffect(()=>{
    checkWidth();
    window.addEventListener("resize", checkWidth);
  },[]);
  useEffect(()=>{
    if (props && props.page2view) {
      setToview(props.page2view);
      if (props.page2view!=="production_mgmt") informWSprod_mgmt(false);
      else informWSprod_mgmt(true);
    }
  },[props]);
  /*
  useEffect(() => {
    const myhost = window.location.host.split('.');
    let newAddress = (myhost.length === 2) ? `https://${biz}.${myhost[0]}.${myhost[1]}` : (myhost.length === 3) ? `https://${biz}.${myhost[1]}.${myhost[2]}` : `https://${biz}.${myhost[1]}.${myhost[2]}`;
    if (biz && biz !== cookies.biz) window.location.href = newAddress;
  }, [biz,cookies.biz]);
*/
  const isLogin = () => {
    try {
      let token = (window.grecaptcha) ? window.grecaptcha.getResponse() : null;
      if (!token && captchaNeeded) {
        if (window.grecaptcha) window.grecaptcha.execute();
        else loadRecaptcha();
        setPendingToken("login");
      }
      else {
        setPendingToken(0);
        let data2send = {
          token: token,
          keepopen: keepsessionopen,
          biz: biz
        };
        let allgood = true;
        let newPendingItems = {}
        newPendingItems.recaptcha = !token;
        if (!token && captchaNeeded) allgood = false;
        if (registerWith === 0) {
          data2send = {...data2send, registredwith: "email"}
          if (!usernameData || !emailRegex.test(usernameData)) {
            newPendingItems.username = true;
            allgood = false;
          }
          else {
            newPendingItems.username = false;
            data2send = {...data2send, username: usernameData}
          }
          if (!passwordData || passwordData.length < 8) {
            newPendingItems.password = true;
            allgood = false;
          }
          else {
            newPendingItems.password = false;
            data2send = {...data2send, password: passwordData}
          }
          if (newPasswordData.text1.length > 7 && newPasswordData.text2.length > 7 && newPasswordData.text1 === newPasswordData.text2) {
            data2send = {...data2send, newpass: newPasswordData.text1}
          }
        }
        else if (registerWith === 1) {
          data2send = {...data2send, registredwith: "mobile", country: countryCode}
          if (!mobileData || !phoneRegex.test(mobileData)) {
            newPendingItems.mobile = true;
            allgood = false;
          }
          else {
            newPendingItems.mobile = false;
            data2send = {...data2send, mobile: mobileData}
          }
          if (!codeData || codeData.length < 6) {
            newPendingItems.code = true;
            allgood = false;
          }
          else {
            newPendingItems.code = false;
            data2send = {...data2send, code: codeData}
          }
        }
        if (allgood) {
          post("/server/userlogin", data2send)
          .then((response) => {
            if (captchaNeeded) grecaptchaRestart();
            if (response.error && response.error === "wrongmobile") setPendingItems({...pendingItems, mobile: true, mobileerror: true});
            else if (response.step && response.step ==="addnewpass") setNewPass(true);
            else if (response.success && response.success === "sms-sent") setPendingItems({...pendingItems, mobile: false, newcodesent: true});
            else if (response.newpasssent) newpasssent();
            else if (response.success) {
              setLogin(false);
              if (response.privileges) setPrivilegesModel(response.privileges);
              if (response.workflows) setWorkflowsModel(response.workflows);
              let user_data = response.success;
              if (user_data.privileges) user_data.privileges = JSON.parse(user_data.privileges);
              setUserData(user_data);
              if ((!isNaN(user_data.active) && user_data.active === 0) || (response.workflows.employeedata === "required" && !response.success.employeedata)) assignPage2view("mydata");
              else if (response.success.role < 50) assignPage2view("dailyshift");
              else assignPage2view("dailyshifts");
              getWOitems();
            }
            else if (response.error) {
              if (response.error === "unknownuser") setLogingError("Usuario desconocido, hable con su administrado");
              else if (response.error === "unknownmobile") setLogingError("Teléfono desconocido, hable con su administrado");
              else if (response.error === "wrong-pass") setLogingError("Constraseña errónea, si no la recuerda clique 'he olvidado mi contraseña'");
              else if (response.error === "wrong-code") setLogingError("Código erróneo, si no lo recuerda clique 'enviar un nuevo código'");
              else if (response.error === "wrongmobileformat") setLogingError("Revise el formato del número de teléfono");
            }
            if (response.ws) setws_id(response.ws);
          });
        }
        else setPendingItems({...newPendingItems});
      }
    }
    catch(err) {
      loadRecaptcha();
      setPendingToken("login");
    }

  }
  function newpasssent() {
      setUnknownUser(false);
      setLostPass(false);
      setNewPass(true);
  }
  const isNewPass = (event) => {
    let token = (window.grecaptcha) ? window.grecaptcha.getResponse() : null;
    if (!token) {
      if (window.grecaptcha) window.grecaptcha.execute();
      else loadRecaptcha();
      setPendingToken("newpass");
    }
    else if (usernameData && emailRegex.test(usernameData)) {
      setPendingToken(0);
      post("/server/newpass", {
        token: token,
        username: usernameData,
        biz: biz
        })
      .then((response) => {
        grecaptchaRestart();
        if (response.newpasssent) newpasssent();
        else {
          setUnknownUser(true);
          setPendingItems({...pendingItems, username: true});
        }
      });
    }
    else  {
      let newPendings = {...pendingItems};
      newPendings.recaptcha = !token;
      newPendings.username = (!usernameData || !emailRegex.test(usernameData));
      setPendingItems({...newPendings});
    }
  }
  const isNewSMS = (event) => {//console.log("isnewsms",pendingToken)
    let token = (window.grecaptcha) ? window.grecaptcha.getResponse() : null;
    if (!token) {
      if (window.grecaptcha) window.grecaptcha.execute();
      else loadRecaptcha();
      if (pendingToken !== "newsms") setPendingToken("newsms");
    }
    else {
      if (mobileData && phoneRegex.test(mobileData)) {
          setPendingToken(0);
          post("/server/newsms", {
            token: token,
            country : countryCode,
            mobile: mobileData,
            biz: biz
            })
          .then((response) => {
            grecaptchaRestart();
            if (response.success) {
              setUnknownUser(false);
              setNewCode(false);
              setPendingItems({mobile:false, code:false, username:false, password: false, recaptcha:false});
            }
            else {
              setUnknownUser(true);
              setPendingItems({...pendingItems, mobile: true, mobileerror: true});
            }
          });
      }
      else {
        let newPendings = {...pendingItems};
        newPendings.mobile = (!mobileData || !phoneRegex.test(mobileData));
        setPendingItems({...newPendings});
      }
    }
  }

  const clearData = () => {
    setUserData();
    setUnknownUser(false);
    setLostPass(false);
    setNewCode(false);
    setNewPass(false);
    setNewPasswordData({text1:"", text2:"", success1:false, success2:false});
    setPendingItems({mobile:false, code:false, username:false, password: false, recaptcha:false});
  }
  const changeRegistrationType = (event, newValue) => {
    clearData();
    setRegisterWith(newValue);
  }
  const closeSession = () => {
    setAnchorUserMenu(null);
    get('/server/logout').then((response)=>{
      clearData();
      setLogin(true);
      setViewSideMenu(false);
      assignPage2view("");
    })
  }
  const redirecting2reports = (rejected = 0) => {
    if (rejected > 0) getWOitems();
    assignPage2view("myshifts");
  }
  const gotoreports = (e) => {
    if (editingDate && userData.role > 49) assignPage2view("dailyshifts");
    else redirecting2reports(e);
  }
  const bizChange = (e) => {
    let text = (e.target.value) ? e.target.value.toLowerCase() : "";
    if (allBiz.map(e=>e.id).includes(text)) setBiz(text);
    else setBiz();
  }
  const employeeHasPersonalData = (hasData) => {
    if (hasData) {
      let newUserData = {...userData};
      newUserData.employeedata = true;
      setUserData(newUserData);
    }
  }
  const gotoUserDateShift = (date,employee,isnew,employeename) => {
    assignPage2view("dailyshift");
    setEditingDate({date:date,employee:employee,isnew:isnew,name:employeename});
  }
  useEffect(() => {
    if (pendingToken) {
      const f = () => {//console.log("pendingToken",pendingToken)
        if (pendingToken === "login") isLogin();
        else if (pendingToken === "newpass") isNewPass();
        else if (pendingToken === "newsms") isNewSMS();
      };
      const interval = setInterval(f, 500);
      return () => clearInterval(interval);
    }
  }, [pendingToken,isLogin,isNewPass,isNewSMS]);
  const displayFilterText = (obj) => {
    let keys = Object.keys(obj);
    return keys.map(e=>obj[e]);
  }
  return (
          <React.Fragment>
          <CssBaseline />
          {!login &&
          <AppBar position="static" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              {1===0 &&
                  <Typography variant="h6" className={classes.logo_icon}>
                   {logo_icon}
                  </Typography>
              }
              {userData.active === 1 && (workflowsModel.employeedata !== "required" || userData.employeedata) &&
                <IconButton color="inherit" aria-label="menu" onClick={()=>setViewSideMenu(!viewSideMenu)}>
                  {!viewSideMenu &&
                    <MenuIcon />
                  }
                  {viewSideMenu &&
                    <CloseIcon />
                  }
                </IconButton>
                }
                <Typography variant="body1" className={isMobileSize ? classes.topbaruser : classes.topbaruserbig}>
                 {userData.name.toLowerCase()}
                </Typography>
                {rejectedWOs > 0 &&
                  <Typography variant="h6" className={classes.topicon} onClick={()=>assignPage2view("myshifts")}>
                    <Badge badgeContent={rejectedWOs} color="secondary">
                      <ThumbDownIcon/>
                    </Badge>
                  </Typography>
                }
                {proditemsWOs[0].length > 0 &&
                  <>
                    <IconButton color="inherit" aria-label="menu" onClick={(event)=>setAnchorprodItemsMenu(event.currentTarget)}>
                      <Badge badgeContent={proditemsWOs[0].length} color="secondary">
                        <AllInboxIcon/>
                      </Badge>
                    </IconButton>
                    <Menu
                      id="logout-menu"
                      anchorEl={anchorprodItemsMenu}
                      open={openProdItemMenu}
                      onClose={()=>setAnchorprodItemsMenu(null)}
                    >
                      {proditemsWOs[0]?.map((e,p)=>
                        <MenuItem  onClick={()=>assignPage2viewAndClear("production_mgmt",e,proditemsWOs[1][p])}>{e}-{displayFilterText(proditemsWOs[1][p])?.join(" -- ")}</MenuItem>
                      )}
                    </Menu>
                  </>
                }
                {pendingWOs > 0 &&
                  <Typography variant="h6" className={classes.topicon} onClick={()=>assignPage2view("shiftvalidation")}>
                    <Badge badgeContent={pendingWOs} color="secondary">
                      <NotificationsIcon/>
                    </Badge>
                  </Typography>
                }
                <IconButton color="inherit" aria-label="menu" onClick={(event)=>setAnchorUserMenu(event.currentTarget)}>
                  <AccountCircleIcon/>
                </IconButton>
                <Menu
                  id="logout-menu"
                  anchorEl={anchorUserMenu}
                  open={openUserMenu}
                  onClose={()=>setAnchorUserMenu(null)}
                >
                  <MenuItem onClick={closeSession}>Cerrar sesión</MenuItem>
                </Menu>
            </Toolbar>
          </AppBar>
          }
          {!login && viewSideMenu &&
            <Drawer onClose={toggleDrawer} open={viewSideMenu} variant="permanent">
              {listDiv()}
            </Drawer>
          }
          <Box>
            {login &&
              <Container maxWidth="xs">
                <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
                  <Grid item xs={6} id="logoWLA" className={classes.logo}>
                    {logo}
                  </Grid>
                  <Grid item xs={6} id="logoclient">
                    {biz &&
                      <img alt="" src={`/images/logo-${newbiz}.jpg`} className={classes.logoclient}/>
                    }
                  </Grid>
                </Grid>
                <Paper square>
                  <Tabs
                    variant="fullWidth"
                    value={registerWith}
                    indicatorColor="primary"
                    textColor="inherit"

                    onChange={changeRegistrationType}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="email" />
                    <Tab label="móvil" />
                  </Tabs>
                  <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
                    {registerWith === 0 &&
                      <>
                        {allBiz &&
                          <Grid item xs={12} id="getnew">
                            <TextField
                              id="biz"
                              key="biz"
                              variant="outlined"
                              onChange={bizChange}
                              label="empresa"
                              fullWidth
                              InputProps={biz && {
                                endAdornment: <InputAdornment position="stendart"><DoneIcon/></InputAdornment>,
                              }}
                            />
                          </Grid>
                        }
                        <Grid item xs={12}>
                          <TextField id="user" key="user" variant="outlined" onChange={usernameChange} label="email" fullWidth autoFocus className={pendingItems.username ? classes.red : classes.blue}/>
                        </Grid>
                        {unknownUser &&
                          <Grid item xs={12} id="getnew">
                            <Typography variant="body1" className={classes.red}>
                              usuario desconocido
                            </Typography>
                          </Grid>
                        }
                        {!lostpass &&
                          <>
                            <Grid item xs={12}>
                              <TextField id="pass" key="pass" variant="outlined" onChange={passChange} label={newPass ? "contraseña temporal" : "contraseña"} type="password" fullWidth className={pendingItems.password ? classes.red : classes.blue}/>
                            </Grid>
                            {!newPass &&
                              <Grid item xs={12} id="getnew" className={classes.getnew}>
                                <Typography variant="body1" onClick={()=>{setLostPass(true);setNewCode(false)}}>
                                  he olvidado mi contraseña
                                </Typography>
                              </Grid>
                            }
                            {newPass &&
                              <>
                                <Grid item xs={12} id="getnewText">
                                  <Typography variant="body1">
                                    Crea una nueva contraseña, debe tener:
                                  </Typography>
                                  <Typography variant="body1">
                                    &#8226; minúsculas, mayúsculas y números
                                  </Typography>
                                  <Typography variant="body1">
                                    &#8226; un mínimo de 8 caracteres
                                  </Typography>
                                </Grid>
                                <Grid item xs={10} id="newpassword1">
                                  <TextField id="passNew1" key="passNew1" variant="outlined" onChange={newPassChange1} label="nueva contraseña" type="password" fullWidth/>
                                </Grid>
                                <Grid item xs={2} id="newpassword1">
                                  {newPasswordData.success1 && <DoneIcon/>}
                                  {!newPasswordData.success1 && <CloseIcon/>}
                                </Grid>
                                <Grid item xs={10} id="newpassword2">
                                  <TextField id="passNew2" key="passNew2" variant="outlined" onChange={newPassChange2} label="repetir nueva contraseña" type="password" fullWidth/>
                                </Grid>
                                <Grid item xs={2} id="newpassword2">
                                  {newPasswordData.success2 && <DoneIcon/>}
                                  {!newPasswordData.success2 && <CloseIcon/>}
                                </Grid>
                              </>
                            }
                          </>
                        }

                      </>
                    }
                    {registerWith === 1 &&
                      <>
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                           <InputLabel id="countries-codes-label">País</InputLabel>
                           <Select
                             id="countries-codes"
                             value={countryCode}
                             label="País"
                             onChange={(e)=>setCountryCode(e.target.value)}
                           >
                            <MenuItem value="" key="emptyval"></MenuItem>
                            {phone_numbers.map((country,i)=>
                              <MenuItem key={`countrycodes${i}`} value={country[4]}>{country[1]}</MenuItem>
                            )}
                           </Select>
                         </FormControl>
                        </Grid>
                        <Grid item xs={7}>
                          <TextField
                            id="user"
                            key="user"
                            variant="outlined"
                            onChange={mobileChange}
                            label="móvil"
                            fullWidth
                            autoFocus
                            value={mobileData}
                            className={pendingItems.mobile ? classes.red : classes.blue}
                            InputProps={countryCode !== "" && {
                              startAdornment: <InputAdornment position="start">+{countryCode}</InputAdornment>,
                            }}
                          />
                        </Grid>
                        {(pendingItems.mobileerror || pendingItems.newcodesent) &&
                          <Grid item xs={12} id="getnew">
                            <Typography variant="body1" className={classes.red}>
                              {pendingItems.mobileerror && <>Teléfono móvil desconocido</>}
                              {pendingItems.newcodesent && <>Código caducado, se ha enviado un nuevo código</>}
                            </Typography>
                          </Grid>
                        }
                        {!newCode &&
                          <>
                            <Grid item xs={12}>
                              <TextField id="pass" key="pass" variant="outlined" onChange={codeChange} label="código" fullWidth className={pendingItems.code ? classes.red : classes.blue}/>
                            </Grid>
                            <Grid item xs={12} id="getnew" className={classes.getnew}>
                              <Typography variant="body1" onClick={()=>{setNewCode(true);setLostPass(false)}}>
                                enviar un nuevo código
                              </Typography>
                            </Grid>
                          </>
                        }
                      </>
                    }
                    <Grid item xs={12} id="recaptcha" className={pendingItems.recaptcha ? classes.divcenterred : classes.divcenter}/>
                    {!lostpass && !newCode && isMobile &&
                      <Grid item xs={12} className={classes.center}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={keepsessionopen}
                              onChange={()=>setkeepsessionopen(!keepsessionopen)}
                              name="keepsessionopen" />
                            }
                          label="Mantener la sesión abierta"
                        />
                      </Grid>
                    }
                    <Grid item xs={12} align="center" className={classes.loginbottom}>
                      {!lostpass && !newCode &&
                        <Button key="send" variant="contained" onClick={isLogin}>enviar</Button>
                      }
                      {lostpass &&
                        <Button key="newpass" variant="contained" onClick={isNewPass}>crear nueva contraseña</Button>
                      }
                      {newCode &&
                        <Button key="newsms" variant="contained" onClick={isNewSMS}>enviar SMS con código</Button>
                      }
                    </Grid>
                  </Grid>
                </Paper>
              </Container>
            }
            {!login && toview==="mydata" &&
              <EmployeeData isMobileSize={isMobileSize} userData={userData} active={userData.active} datasaved={employeeHasPersonalData}/>
            }
            {!login && userData.active === 1 && (workflowsModel.employeedata !== "required" || userData.employeedata) &&
              <>
                {toview==="clients" &&
                  <ClientsReview isMobileSize={isMobileSize}/>
                }
                {toview==="employees" &&
                  <EmployeesReview isMobileSize={isMobileSize} userData={userData} privilegesModel={privilegesModel} retrieveBizData={retrieveBizData}/>
                }
                {toview==="workorders" &&
                  <WorkOrdersReview isMobileSize={isMobileSize} retrieveBizData={retrieveBizData}/>
                }
                {toview==="employeesdata" &&
                  <EmployeesData isMobileSize={isMobileSize}/>
                }
                {toview==="myshifts" &&
                  <MyShifts
                    isMobile={isMobile}
                    isMobileSize={isMobileSize}
                    managers={managers}
                    projects={projects}
                    getWOitems={getWOitems}
                    go2date={gotoUserDateShift}
                    globalVariables={globalVariables}
                    changeGlobalVariables={(e,v)=>changeGlobalVariables(e,v)}
                    />
                }
                {toview==="dailyshift" &&
                  <WorkReport
                    isMobile={isMobile}
                    userData={userData}
                    biz={newbiz}
                    managers={managers}
                    projects={projects}
                    shiftsigned={()=>showPage(5)}
                    gotoreports={gotoreports}
                    editingDate={editingDate}
                    />
                }
                {toview==="shiftvalidation" &&
                  <MyShifts
                    isMobile={isMobile}
                    isMobileSize={isMobileSize}
                    managers={managers}
                    projects={projects}
                    userData={userData}
                    getWOitems={getWOitems}
                    go2date={gotoUserDateShift}
                    changeGlobalVariables={(e,v)=>changeGlobalVariables(e,v)}
                    />
                }
                {toview==="export" &&
                  <Exports
                    isMobileSize={isMobileSize}
                    managers={managers}
                    projects={projects}
                    userData={userData}
                    globalVariables={globalVariables}
                    changeGlobalVariables={(e,v)=>changeGlobalVariables(e,v)}
                    />
                }
                {toview==="dailyshifts" &&
                  <AllShifts
                    isMobileSize={isMobileSize}
                    managers={managers}
                    projects={projects}
                    iamadmin={true}
                    go2date={gotoUserDateShift}
                    globalVariables={globalVariables}
                    changeGlobalVariables={(e,v)=>changeGlobalVariables(e,v)}
                  />
                }
                {toview==="production_edit" &&
                  <ProductionEditData
                    isMobileSize={isMobileSize}
                    managers={managers}
                    projects={projects}
                    iamadmin={true}
                    globalVariables={globalVariables}
                    changeGlobalVariables={(e,v)=>changeGlobalVariables(e,v)}
                  />
                }
                {toview==="production_mgmt" &&
                  <ProductionMgmtData
                    isMobile={isMobile}
                    isMobileSize={isMobileSize}
                    sendMessage={sendMessage}
                    lastMessage={lastMessage}
                    userData={userData}
                    ws_id={ws_id}
                  />
                }
                {toview==="production" &&
                  <ProductionData
                    isMobileSize={isMobileSize}
                    managers={managers}
                    projects={allProjects}
                    iamadmin={true}
                    globalVariables={globalVariables}
                    changeGlobalVariables={(e,v)=>changeGlobalVariables(e,v)}
                  />
                }
                {toview==="analytics" &&
                  <AnalyticsData
                    isMobileSize={isMobileSize}
                    managers={managers}
                    projects={projects}
                  />
                }

              </>
            }
          </Box>
          <Snackbar open={Boolean(loginError)} autoHideDuration={6000} onClose={()=>setLogingError(false)}>
            <MuiAlert elevation={6} variant="filled"  onClose={()=>setLogingError(false)} severity="warning" sx={{ width: '100%' }}>
              {loginError}
            </MuiAlert>
          </Snackbar>
          {1===0 &&
            <div>
              <span onClick={()=>get('/server/testws')}> TEST WS {ws_id}</span>
              <button
                onClick={handleClickSendMessage}
                disabled={readyState !== ReadyState.OPEN}
              >
                Click Me to send 'Hello'
              </button>
              <span>The WebSocket is currently {connectionStatus}</span>
              {lastMessage ? <span>Last message: {lastMessage.data}</span> : null}
              <ul>
                {messageHistory.map((message, idx) => (
                  <span key={idx}>{message ? message.data : null}</span>
                ))}
              </ul>
            </div>
          }
          </React.Fragment>
  );

}
