
import React, { Component } from 'react';
class Legal extends Component {
  render() {
    return (
      <div id="top">
					<ol>
						<li>En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, a continuación se reflejan los siguientes datos: Zyrkia Systems S.L. de dominio web es

							<a href="http://www.zyrkia.com">www.zyrkia.com</a> (en adelante ZYRKIA), con domicilio a estos efectos en calle San José número 66 puerta F, 41701 Dos Hermanas (Sevilla) de N.I.F.: B-90333865 inscrita en el Registro Mercantil de la provincia de Sevilla en el tomo 6.405, folio 20, Hoja número SE-114.426. Correo electrónico de contacto: hola@zyrkia.com del sitio web.
						</li><li><strong>USUARIOS</strong>: El acceso y/o uso de este portal de ZYRKIA creadora del sitio web atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, las Condiciones Generales de Uso aquí reflejadas. Las citadas Condiciones serán de aplicación independientemente de las Condiciones Generales de Contratación que en su caso resulten de obligado cumplimiento.

						</li>
						<li><strong>USO DEL PORTAL</strong>: indicar dominio proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, “los contenidos”) en Internet pertenecientes a ZYRKIA creadora del sitio web o a sus licenciantes a los que el USUARIO pueda tener acceso. El USUARIO asume la responsabilidad del uso del portal. Dicha responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o contenidos. En dicho registro el USUARIO será responsable de aportar información veraz y lícita. Como consecuencia de este registro, al USUARIO se le puede proporcionar una contraseña de la que será responsable, comprometiéndose a hacer un uso diligente y confidencial de la misma. El USUARIO se compromete a hacer un uso adecuado de los contenidos y servicios (como por ejemplo servicios de chat, foros de discusión o grupos de noticias) que ZYRKIA creadora del sitio web ofrece a través de su portal y con carácter enunciativo pero no limitativo, a no emplearlos para:
							<ul>
								<li>incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público;</li>
								<li>difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos;</li>
								<li>provocar daños en los sistemas físicos y lógicos de ZYRKIA creadora del sitio web , de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados;</li>
								<li>intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes. ZYRKIA creadora del sitio web se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultarán adecuados para su publicación. En cualquier caso, ZYRKIA creadora del sitio web no será responsable de las opiniones vertidas por los usuarios a través de los foros, chats, u otras herramientas de participación.</li>
							</ul>

						</li>
						<li><strong>PROTECCIÓN DE DATOS</strong>: ZYRKIA &nbsp;creadora del sitio web cumple con las directrices de la Ley Orgánica 15/1999 de 13 de diciembre de Protección de Datos de Carácter Personal, el Real Decreto 1720/2007 de 21 de diciembre por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica y demás normativa vigente en cada momento, y vela por garantizar un correcto uso y tratamiento de los datos personales del usuario. Para ello, junto a cada formulario de recabo de datos de carácter personal, en los servicios que el usuario pueda solicitar a ZYRKIA &nbsp;creadora del sitio web, hará saber al usuario de la existencia y aceptación de las condiciones particulares del tratamiento de sus datos en cada caso, informándole de la responsabilidad del fichero creado, la dirección del responsable, la posibilidad de ejercer sus derechos de acceso, rectificación, cancelación u oposición, la finalidad del tratamiento y las comunicaciones de datos a terceros en su caso. Asimismo, ZYRKIA creadora del sitio web informa que da cumplimiento a la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Información y el Comercio Electrónico y le solicitará su consentimiento al tratamiento de su correo electrónico con fines comerciales en cada momento.
						</li><li><strong>PROPIEDAD INTELECTUAL E INDUSTRIAL</strong>: ZYRKIA creadora del sitio web por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de ZYRKIA creadora del sitio web o bien de sus licenciantes. Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de ZYRKIA creadora del sitio web . El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de ZYRKIA creadora del sitio web. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en el las páginas de ZYRKIA creadora del sitio web.
						</li><li><strong>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</strong>: ZYRKIA creadora del sitio web. no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.
						</li><li><strong>MODIFICACIONES</strong>: ZYRKIA creadora del sitio web se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en su portal.
						</li><li><strong>ENLACES</strong>: En el caso de que en nombre del dominio se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, ZYRKIA creadora del sitio web no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso ZYRKIA creadora del sitio web asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet. Igualmente la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.
						</li><li><strong>DERECHO DE EXCLUSIÓN</strong>: ZYRKIA creadora del sitio web se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.
						</li><li><strong>GENERALIDADES</strong>: ZYRKIA creadora del sitio web perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.
						</li><li><strong>MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN</strong>: ZYRKIA a creadora del sitio web podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
						</li><li><strong>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</strong>: La relación entre ZYRKIA creadora del sitio web y el USUARIO se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y tribunales de la ciudad de TU CIUDAD.</li>
					</ol>

				</div>
        )
      }
  }
export default Legal;
