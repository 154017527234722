import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import Box from '@mui/material/Box';
import Login from './Login';
import Welcome from './welcome';

const host = window.location.host.split('.');
const oldurl = (host.length === 3 && host[0] !== "www" && host[0] !== "admin" && ((host[1] === "workload" && host[2] === "agency") || (host[1] === "zyrkia" && host[2] === "com")));

export default function home() {
  return (
    <Router>
      <Box display="flex" flexDirection="column" style={{minHeight: "100vh"}}>
        <Routes>
          {oldurl &&
            <Route path="/" element={<Login page2view="" redirectbiz={host[0]}/>} />
          }
          {!oldurl &&
            <Route path="/" element={<Welcome/>} />
          }
          <Route path="/mydata" element={<Login page2view="mydata"/>} />
          <Route path="/clients" element={<Login page2view="clients"/>} />
          <Route path="/employees" element={<Login page2view="employees"/>} />
          <Route path="/workorders" element={<Login page2view="workorders"/>} />
          <Route path="/employeesdata" element={<Login page2view="employeesdata"/>} />
          <Route path="/myshifts" element={<Login page2view="myshifts"/>} />
          <Route path="/dailyshift" element={<Login page2view="dailyshift"/>} />
          <Route path="/shiftvalidation" element={<Login page2view="shiftvalidation"/>} />
          <Route path="/dailyshifts" element={<Login page2view="dailyshifts"/>} />
          <Route path="/production_edit" element={<Login page2view="production_edit"/>} />
          <Route path="/production_mgmt" element={<Login page2view="production_mgmt"/>} />
          <Route path="/production" element={<Login page2view="production"/>} />
          <Route path="/export" element={<Login page2view="export"/>} />
          <Route path="/analytics" element={<Login page2view="analytics"/>} />
          <Route path="/login/:pathbiz" element={<Login page2view=""/>} />
          <Route path="/:id" element={<Login page2view=""/>} />
          <Route path="/biz/:biz_id" element={<Login page2view=""/>} />
        </Routes>
      </Box>
    </Router>
  )
}
