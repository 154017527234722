import React, { useEffect } from 'react';

import Container from '@mui/material/Container';












import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';



import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';



import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Dexie from 'dexie';


import { useStyles } from "./styles";
import {get, post, postPlain, urlBase64ToUint8Array, applicationServerKey, db} from "./commons";

export default function EmployeeData({userData,active,datasaved}) {

  const classes = useStyles();

  const [myData, setMyData] = React.useState();



  const [displayHidden,setdisplayHidden] = React.useState(false);
  const [dataModel, setDataModel] = React.useState([]);
  const [circulars, setCirculars] = React.useState([]);
  const [warnings, setWarnings] = React.useState({});
  const [required, setRequired] = React.useState({});
  const [isSaved, setisSaved] = React.useState(false);
  const [missingdata, setmissingdata] = React.useState(1);
  const [havePushData, setHavePushData] = React.useState(false);
  const [defElements, setDefElements] = React.useState([]);

  const getNotifFromIndexedDB = async () => {
    const userPush = await db.notifications.toArray();
    if (userPush[0] && userPush[0].code && userPush[0].biz) {
     if (userPush[0].code === userData.code && userPush[0].biz === userData.biz) {
       setHavePushData(true);
     }
    }
  }
  useEffect(()=> {
    if (userData.code && userData.biz) getNotifFromIndexedDB()
  },[userData])

  const changeCircular = (index,val) => {
    let newCirulars = [...circulars];
    newCirulars[index] = val;
    setCirculars(newCirulars);
  }
  const changeMyData = (id,val,j,k) => {//console.log("changeMyData",id,val,j,k,myData)
    let newData = {...myData}
    if (!isNaN(j) && !isNaN(k)) {
      if (!newData[id]) newData[id] = [];
      if (!newData[id][j]) newData[id][j] = [];
      newData[id][j][k] = val
    }
    else newData[id] = val;
    setMyData(newData);
  }

  const deletefile = (element,index) => {
    changeCircular(index,true);
    get('/server/request/deldocbytype?type=cvdocument').then((response)=>{
      if (response.deleted) {
        changeMyData(element.id,null);
        changeCircular(index,false);
      }
    });
  }
  const selectFile = (event,element,index) => {
    changeCircular(index,true);
    let formData = new FormData();
    formData.append("cvdocument", event.target.files[0]);
    postPlain('/server/request/cvdocupload',formData,{'enctype': 'multipart/form-data'}).then((response)=>{
      if (response.stored) {
        changeMyData(element.id,response.stored);
        changeCircular(index,false);
      }
    })
  }
  const savedata = () => {
    let pendingItems = dataModel.filter((e,index)=>{
      return (required[index] && (!myData[e.id] || warnings[e.id]))
    });
    if (pendingItems.length === 0) {
      let defVals = defElements.reduce((a,c)=>{
        a[c.def] = myData[c.id];
        return a;
      },{});
      post('/server/request/storemydata',{myData,defVals}).then((response)=>{
        if (response.update) {
          setisSaved(true);
          datasaved(true);
        }
      });
    }
    else setmissingdata(2)
  }
  useEffect(()=>{
    registerServiceWorker();
    get('/server/request/employeedata').then((response)=>{
      if (response.model && response.model[0] && response.model[0].model) {
        let modelElements = JSON.parse(response.model[0].model);
        if (modelElements && modelElements.elements) {
          setDataModel(modelElements.elements);
          let allrequired = modelElements.elements.map((e)=>e.required);
          let fileIDs = [];
          setRequired(allrequired);
          let allData = (response.data) ? JSON.parse(response.data) : [];
          let newDefElements = [];
          modelElements.elements.forEach((e)=>{
            if (!allData[e.id] && e.hasOwnProperty("default")) allData[e.id] = e.default;
            if (e.type==="file" && e.db) fileIDs.push({id:e.id,file:e.db});
            if (e.hasOwnProperty("def")) {
              if (e.def === "email" && response.email) allData[e.id] = response.email;
              else if (e.def === "mobile" && response.mobile) allData[e.id] = (response.mobile && response.mobile.length > 5) ? response.mobile : e.default;
              newDefElements.push({def:e.def,id:e.id});
            }
          })
          setDefElements(newDefElements);
          if (fileIDs.length > 0) {
            let newData = {...allData}
            let pending = fileIDs.length;
            for (let k=0;k<fileIDs.length;k++) {
              get(`/server/request/getdocbytype?type=${fileIDs[k].file}`).then((response)=>{
                pending--;
                if (response.file && response.file[0] && response.file[0].name) {
                  newData[fileIDs[k].id] = response.file[0].name;
                }
                if (pending === 0) {
                  setMyData(newData);
                }
              });
            }
          }
          else setMyData({...allData});
        }
      }
    })
  },[]);
  const checkData = (element,val) => {
    if (element.regex) {
      let regextext = element.regex;
      const regexExpression = new RegExp(regextext, 'g');
      let newWarnings = {...warnings};
      if (!regexExpression.test(val)) newWarnings[element.id] = classes.red;
      else newWarnings[element.id] = null;
      setWarnings(newWarnings);
    }
  }
  const displaylabel = (element) =>{
    if (element.required) return `${element.label} *`
    else return element.label
  }
  const addItems = (index,repite) => {
    let newDataModel = [...dataModel];
    newDataModel[index].repite = repite + 1;
    setDataModel(newDataModel);
  }
  const displayText = (element,index) => {
    return (
      <Grid item xs={Boolean(element.width) ? element.width : 6} key={`text-${index}`} className={missingdata > 1 && element.required && !myData[element.id]? classes.red : ""}>
        <TextField
          id={element.id}
          label={`${displaylabel(element)}`}
          value={myData[element.id]}
          fullWidth
          onChange={(e)=>changeMyData(element.id,e.target.value)}
          onBlur={(e)=>checkData(element,e.target.value)}
          className={warnings[element.id]}/>
      </Grid>
    )
  }
  const displayMultipleTexts = (element,index) => {
    let widths = element.items.reduce((a,i)=>{a+=i.width;return a},0)
    let emptywidth = 12 - (widths % 12);
    let repite = (element.repite && myData[element.id]  && myData[element.id] && element.repite < myData[element.id].length) ? myData[element.id].length : (element.repite) ? element.repite : 1;
    let elements = [];
    let crossWdith = 1;
    for (let j=0;j<repite;j++) {

      elements.push( element.items.map((e,index2) => {
        let fieldVal = (myData[element.id] && myData[element.id][j] && myData[element.id][j][index2]) ? myData[element.id][j][index2] : "";
        return (
         <Grid item xs={e.width} key={`multitext-${index}-${index2}`}>
          <TextField id="curso"
            fullWidth
            className={missingdata > 1 && element.required && !myData[element.id]? classes.red : ""}
            label={e.id}
            value={fieldVal}
            onChange={(e)=>changeMyData(element.id,e.target.value,j,index2)}
          />
        </Grid>
      )}
     ));
      if (emptywidth > 0 && j+1 < repite) elements.push( <Grid item xs={emptywidth}/> )
      if (j+1 === repite) elements.push(
        <>
          <Grid item xs={crossWdith} className={classes.bottom}><AddIcon onClick={()=>addItems(index,repite)} key={`multitext-last-${index}`}/></Grid>
          {(12 - crossWdith - widths)> 0 &&
            <Grid item xs={12 - crossWdith - widths} key={`multitext-last2-${index}`}/>
            }
        </>
      )
    }
    return elements
  }
  function registerServiceWorker() {
    return navigator.serviceWorker
      .register('./service-worker.js')
      .then(function (registration) {
        //console.log('Service worker successfully registered.');
        return registration;
      })
      .catch(function (err) {
        console.error('Unable to register service worker.', err);
      });
  }
  function askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (result) {
        resolve(result);
      });
      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== 'granted') {
        console.error("We weren't granted permission.");
      }
      else {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then(function (registration) {
            const subscribeOptions = {
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array( applicationServerKey ),
            };
            return registration.pushManager.subscribe(subscribeOptions);
          })
          .then(function (pushSubscription) {
            post('/server/request/storepushsubscription',{data:pushSubscription}).then( (response)=>{
              if (response.success) {
                db.notifications.bulkPut([
                   { id: 1, code:userData.code, biz:userData.biz, data:JSON.parse(JSON.stringify(pushSubscription)) },
                 ])
                 .then( async () => {
                   setHavePushData(true);
                   } )
                 .catch(Dexie.BulkError, function (e) {
                      console.error (e);
                  });
              }
            });
          });
      }
    });
  }
  function sendnotif() {
    get('/server/request/cancelnotifications').then((response)=>{
      if (response.success) {
        setisSaved(true);
        setHavePushData(false);
      }
    });
  }
  return (
<Container maxWidth="md" className={classes.right}>
  <Typography variant="h5" align="center" color="textSecondary" paragraph>
  <br/> Mi Perfil
  </Typography>
  <FormGroup>
    <Grid container justify="space-evenly" alignItems="center" spacing={1}>
    {active === 1 && !havePushData && 'serviceWorker' in navigator && 'PushManager' in window &&
      <Grid item xs={12} className={classes.end}>
        <Button variant="contained" onClick={askPermission}>activar notificaciones</Button>
      </Grid>
    }
    {active === 1 && havePushData && 'serviceWorker' in navigator && 'PushManager' in window &&
      <Grid item xs={12} className={classes.end}>
        <Button variant="contained" onClick={sendnotif}>cancelar notificaciones</Button>
      </Grid>
    }
    {myData && dataModel.map((element,index)=>
      <>
        {element.type === "title" &&
          <Grid item xs={12} className={classes.bold} key={`title-${index}`}>
            {element.label}
          </Grid>
        }
        {element.type === "file" &&
          <>
            {myData[element.id] &&
              <>
                <Grid item xs={4} key={`file0-${index}`} className={missingdata > 1 && element.required && !myData[element.id]? classes.red : ""}>
                  {element.label}:
                </Grid>
                <Grid item xs={7} key={`file1-${index}`}>
                  <a href={`/server/request/docdownload?id=${myData[element.id]}`}>{myData[element.id]}</a>
                </Grid>
                {!circulars[index] &&
                  <Grid item xs={1} className={classes.bold} key={`file2-${index}`}>
                    <DeleteIcon onClick={()=>deletefile(element,index)}/>
                  </Grid>
                }
              </>
            }
            {!myData[element.id] &&
              <Grid item xs={Boolean(element.width) ? element.width : 11} className={classes.bold} key={`file3-${index}`}>
                <input
                  accept='application/msword,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation'
                  className={classes.nodisplay}
                  id="uploaDOC"
                  multiple
                  type="file"
                  name="cvdocument"
                  onChange={(e)=>selectFile(e,element,index)}
                />
                <label htmlFor="uploaDOC" >
                  <Button
                    variant="contained"
                    size="small"
                    component="span"
                    className={classes.button1}
                    startIcon={<AddIcon />}
                  > {element.label} </Button>
                </label>
              </Grid>
            }
            {circulars[index] &&
              <Grid item xs={1} className={classes.bold} key={`file4-${index}`}>
                <CircularProgress/>
              </Grid>
            }
          </>
        }
        {element.type === "multipletexts" && displayMultipleTexts(element,index)}
        {element.type === "text" && displayText(element,index)}
        {element.type === "checkbox" &&
          <Grid item xs={Boolean(element.width) ? element.width : 6} className={classes.topgapright} key={`checkbox-${index}`}>
            <Checkbox
              color="default"
              checked={myData[element.id]}
              onClick={(e)=>changeMyData(element.id,e.target.checked)} />
              <span className={missingdata > 1 && element.required && !myData[element.id]? classes.red : ""}>
                {element.label}
              </span>
          </Grid>
        }
        {element.type === "date" &&
          <Grid item xs={Boolean(element.width) ? element.width : 6} className={classes.topgapright} key={`date-${index}`}>
            <TextField
              id={element.id}
              label={element.label}
              type="date"
              value={myData[element.id]}
              fullWidth
              className={missingdata > 1 && element.required && !myData[element.id]? classes.red : ""}
              onChange={(e)=>changeMyData(element.id,e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        }
        {element.type === "checkboxhiddentext" &&
          <>
            <Grid item xs={1} key={`checkboxhiddentext1-${index}`}>
              <Checkbox
                color="default"
                checked={myData[element.id]}
                onClick={(e)=>changeMyData(element.id,e.target.checked)} />
            </Grid>
            <Grid item xs={11} key={`checkboxhiddentext2-${index}`} className={missingdata > 1 && element.required && !myData[element.id]? classes.red : ""}>
              {element.label}
              <span onClick={()=>setdisplayHidden(!displayHidden)} className={classes.strongpointer}>
                {element.link}
              </span>
            </Grid>
            {displayHidden && element.hidden.map((e,i)=>
              <>
                <Grid item xs={1} key={`checkboxhiddentext3-${index}-${i}`}/>
                <Grid item xs={10} key={`checkboxhiddentext4-${index}-${i}`}> {e} </Grid>
                <Grid item xs={1} key={`checkboxhiddentext5-${index}-${i}`}/>
              </>
            )}
          </>
        }
        {element.type === "dropdown" &&
          <Grid item xs={Boolean(element.width) ? element.width : 6} key={`dropdown-${index}`}>
            <FormControl fullWidth className={missingdata > 1 && element.required && !myData[element.id]? classes.red : ""}>
              <InputLabel id="tipohoraslabl">
                {element.label}
                </InputLabel>
              <Select
                id="tipohoras"
                value={myData[element.id] && myData[element.id]}
                onChange={(e)=>changeMyData(element.id,e.target.value)}
                >
                {element.options.map((option,i)=>
                  <MenuItem value={option} key={i}>{option}</MenuItem>
                )}
              </Select>
            </FormControl>
        </Grid>
        }
      </>
    )}
    <Grid item xs={12} align="center" className={classes.loginbottom} key="save">
      <Button variant="contained" onClick={savedata}>guardar</Button>
    </Grid>
  </Grid>
</FormGroup>
    <Snackbar open={isSaved} autoHideDuration={6000} onClose={()=>setisSaved(false)}>
      <MuiAlert elevation={6} variant="filled"  onClose={()=>setisSaved(false)} severity="success" sx={{ width: '100%' }}>
        Guardado!
      </MuiAlert>
    </Snackbar>
    <Snackbar open={Boolean(missingdata===2)} autoHideDuration={6000} onClose={()=>setmissingdata(3)}>
      <MuiAlert elevation={6} variant="filled"  onClose={()=>setmissingdata(3)} severity="warning" sx={{ width: '100%' }}>
        Debes competar todos los campos obligatorios!
      </MuiAlert>
    </Snackbar>
</Container>
);

}
