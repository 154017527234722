import React, { useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

import { useStyles } from "./styles";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CameraCropper({src,croppedImg,type,cancel}) {
  const classes = useStyles();
  const filename = 'newFile.jpeg';
  const [crop, setCrop] = React.useState();
  const [imageRef, setImageRef] = React.useState();
  const [srcBlob, setSrcBlob] = React.useState();

  const handleSave = () => {
    makeClientCrop(crop);
    setSrcBlob();
    setImageRef();
    setCrop();
  };
  const handleClose = () => {
    setSrcBlob();
    setImageRef();
    setCrop();
    cancel();
  };
  function onImageLoaded(e) {
    setImageRef(e.currentTarget);
  };

  async function makeClientCrop(crop) {
    if (imageRef) {
      let dataCrop = (crop?.width && crop?.height) ? crop : {width: imageRef.width, height: imageRef.height, x:0, y: 0}
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        dataCrop,
        filename
      );
      croppedImg(croppedImageUrl);
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const cropScaleX = crop.width / image.width;
    const cropScaleY = crop.height / image.height;
    var width = image.naturalWidth * cropScaleX; //crop.width;
    var height = image.naturalHeight * cropScaleY; //crop.height;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );
    return new Promise((resolve, reject) => {
      let newimag = canvas.toDataURL("image/jpeg",0.7)
      resolve(newimag);
    });
  }

  return (
    <div>
      {(src &&
        <Dialog fullScreen open={Boolean(src)} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                size="large">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">
                cancelar
              </Typography>
            </Toolbar>
          </AppBar>
          <Box className={classes.reactCrop}>
            <ReactCrop
              crop={crop}
              onChange={c => setCrop(c)}>
                <img
                  src={src}
                  onLoad={onImageLoaded}/>
            </ReactCrop>
          </Box>
           <DialogActions>
          <Button autoFocus color="primary" onClick={handleSave}>
            descargar texto seleccionado
          </Button>
           </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
