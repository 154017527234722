import React, { useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DvrIcon from '@mui/icons-material/Dvr';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import BusinessIcon from '@mui/icons-material/Business';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import DoneIcon from '@mui/icons-material/Done';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TodayIcon from '@mui/icons-material/Today';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BadgeIcon from '@mui/icons-material/Badge';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ConstructionIcon from '@mui/icons-material/Construction';
import Menu from '@mui/material/Menu';
import PublishIcon from '@mui/icons-material/Publish';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import StarIcon from '@mui/icons-material/Star';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import useWebSocket, { ReadyState } from "react-use-websocket";
import { useCookies } from "react-cookie";
import { useStyles } from "./styles";
import {get, post, logo, logo_icon} from "./commons";
import Legal from "./legal";
import Privacy from "./privacy";
import Contact from "./contact";

export default function Welcome() {
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies();
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState("home");
  const isMobile = (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) ? true : false;
  return (
          <React.Fragment>
          <CssBaseline />
          <AppBar position="static" className={classes.home_appBar}>
            <Toolbar className={classes.toolBar}>
              <div className={classes.home_logo} onClick={()=>setCurrentPage("home")}>
                {logo}
              </div>
          {!isMobile &&
            <>
              <div className={classes.home_gap}/>
                <Typography variant="body1">
                 Productos y Servicios
                </Typography>
              <div className={classes.home_gap}/>
                <Typography variant="body1">
                 Sobre nosotros
                </Typography>
              <div className={classes.home_gap}/>
                <Typography variant="body1" className={classes.home_topbar_titles} onClick={()=>navigate(`/login`)}>
                 Acceso clientes
                </Typography>
              <div className={classes.home_gap}/>
              <div className={classes.home_topbar_titles}>
                <Button variant="outlined" startIcon={<StarIcon />} className={classes.home_topbar_button} onClick={()=>setCurrentPage("contactus")}>
                  Contáctanos
                </Button>
              </div>
            </>
          }
          {isMobile &&
            <Button variant="outlined" className={classes.home_topbar_button} onClick={()=>navigate(`/login`)}>
              Acceso clientes
            </Button>
          }
              <div className={classes.home_gap}/>
          </Toolbar>
        </AppBar>
          <Box>
              <Container maxWidth="lg">
                <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
                  {currentPage === "home" &&
                  <>
                    <Grid item md={12} id="logoWLA" className={!isMobile ? classes.logo : classes.center100}>
                      <img src="/images/home_top2.svg" className={classes.borderRadius10}/>
                      {!isMobile &&
                        <div className={classes.home_img_top_text}>
                          Conoce la productividad de tu empresa en tiempo real<br/>
                          <Button variant="outlined" startIcon={<StarIcon />} className={classes.home_top_button} onClick={()=>setCurrentPage("contactus")}>
                            Contáctanos
                          </Button>
                        </div>
                      }
                    </Grid>
                    <Grid item md={12} id="subheader1" className={classes.aligncenter}>
                      <Typography variant="h4">
                        Toma decisiones eficaces con datos reales, actualizados y precisos.
                      </Typography>
                      <Typography variant="h6">
                        Te ayudamos a obtener la información que necesitas sobre la operativa diaria de tu equipo y el estado de la producción.
                      </Typography>
                    </Grid>

                    {isMobile &&
                      <Grid item md={12} className={classes.aligncenterbutton}>
                      <div>
                        <Button variant="outlined" startIcon={<StarIcon />} className={classes.home_topbar_button} onClick={()=>setCurrentPage("contactus")}>
                          Contáctanos
                        </Button>
                      </div>
                      </Grid>
                    }
                    <Grid item md={12}>&nbsp;</Grid>
                    <Grid item md={12}>
                      <Typography gutterBottom variant="h5" component="div" className={classes.home_subheaders}>
                        <span>Digitaliza la última milla de la información</span>
                      </Typography>
                    </Grid>

                    <Grid item md={4} id="card1">
                      <Card sx={{ maxWidth: 345 }}>
                        <CardContent>
                          <CardMedia
                            sx={{ height: 240 }}
                            image={"/images/homecard1.png"}
                            title="team"
                          />
                          <Typography gutterBottom variant="h5" component="div">
                            Equipo
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Gestiona horarios laborales, hora de entrada y salida,
                            festivos, permisos y gastos de desplazamiento.<br/>
                            Sabrás quién trabajo, donde, a qué horas y durante cuanto tiempo.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={4} id="card3">
                      <Card sx={{ maxWidth: 345 }}>
                        <CardContent>
                          <CardMedia
                            sx={{ height: 240 }}
                            image={"/images/homecard3.png"}
                            title="team"
                          />
                          <Typography gutterBottom variant="h5" component="div">
                            Información siempre actualizada
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Planifica la producción diaria de cada empleado.<br/>
                            Asigna tareas y verifica el estado de ejecución.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={4} id="card2">
                      <Card sx={{ maxWidth: 345 }}>
                        <CardContent>
                          <CardMedia
                            sx={{ height: 240 }}
                            image={"/images/homecard2.png"}
                            title="team"
                          />
                          <Typography gutterBottom variant="h5" component="div">
                            Productividad
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Conoce en tiempo real la producción diaria,
                            qué empleados han faltado o llegado tarde,
                            cuanto produce cada empleado y el ratio de rechazos.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    </>
                  }
                {currentPage === "legal" && <Legal/>}
                {currentPage === "privacy" && <Privacy/>}
                {currentPage === "contactus" && <Contact/>}
                  <Grid item md={12}>&nbsp;</Grid>
                  <Grid item md={12}>
                    <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
                      <Grid item lg={4} id="copy">
                        <Typography gutterBottom variant="body1" component="p" className={classes.home_bottom_nav_p} onClick={()=>setCurrentPage("home")}>
                          <strong>&copy; Zyrkia Systems S.L. 2024</strong>
                        </Typography>
                      </Grid>
                      <Grid item lg={4} id="legal">
                        <Typography gutterBottom variant="body1" component="p" className={classes.home_bottom_nav_p} onClick={()=>setCurrentPage("legal")}>
                          Aviso legal
                        </Typography>
                      </Grid>
                      <Grid item lg={4} id="privacy">
                        <Typography gutterBottom variant="body1" component="p" className={classes.home_bottom_nav_p} onClick={()=>setCurrentPage("privacy")}>
                          Politica de privacidad
                        </Typography>
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </React.Fragment>
  );

}
