import React, { useEffect, Component } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TablePagination from '@mui/material/TablePagination';
import SaveIcon from '@mui/icons-material/Save';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { useCookies } from "react-cookie";

import { useStyles } from "./styles";
import {get, post} from "./commons";

function Row({ row, model }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogData, setdialogData] = React.useState([]);

  const showData = (row) => {
    let keys = Object.keys(row);
    let newRow = keys.map((e)=>{
      let modelval = model.filter((f)=>f.id===e);
      if (modelval[0].def != "privacypolicy") {
        let name = modelval[0].label;//console.log({id:e,val:row[e],label:name,type:modelval[0].type})
        return {id:e,val:row[e],label:name,type:modelval[0].type}
      }
    }).filter(n => n);
    setdialogData(newRow);
    setOpenDialog(true);
  }
  const hiddeData = () => {
    setdialogData([]);
    setOpenDialog(false);
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.shiftTable}>
        {model.map((e,i)=>
          <>
            {e.required && e.required === 1 && e.def != "privacypolicy" &&
              <TableCell key={i} onClick={()=>showData(row)}>
                {row[e.id]}
              </TableCell>
            }
          </>
        )}
      </TableRow>

      <Dialog onClose={hiddeData} open={openDialog}>
        <DialogTitle>
          data
          <IconButton aria-label="close" onClick={hiddeData}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <Grid container spacing={1} className={classes.dialogShifts}>
        {dialogData.map((e)=>
          <>
            <Grid item xs={4}>
              <Typography variant="body1" >{e.label}</Typography>
            </Grid>
            <Grid item xs={8}>
              {e.type === "file" &&
                <a href={`/server/request/docdownload?id=${e.val}`}><SaveIcon/></a>
              }
              {e.type === "multipletexts" && e.val.map((f)=>
                <li>{f}</li>
              )}
              {e.type === "checkbox" && Boolean(e.val) && <CheckIcon/> }
              {e.type === "checkbox" && !Boolean(e.val) && <CancelIcon/> }
              {!["file","multipletexts","checkbox"].includes(e.type) &&
                <Typography variant="body1" >{e.val}</Typography>
              }
            </Grid>
          </>
        )}
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}

export default function WorkReport({biz,shiftsigned,managers,projects}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [employeesData, setemployeesData] = React.useState([]);
  const [model, setModel] = React.useState();
  const [orderBy, setOrderBy] = React.useState([null,false]);

  const retriveData = (pageVal=page, rowsVal=rowsPerPage) => {
    get(`/server/request/employeesdata`).then((response)=>{
      if (response.data) {
        let dataJSON = response.data.map((e)=>JSON.parse(e.employeedata));
        if (dataJSON) {
          setemployeesData(dataJSON);//console.log(dataJSON)
          setTotalRows(dataJSON.length)
        }
      }
      if (response.model){
        let modelJSON = JSON.parse(response.model);
        if (modelJSON.elements) {
          setModel(modelJSON.elements);
        }
      }
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    //retriveData(newPage,rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
    //retriveData(0,rows);
  };
  const displayEmployeeData = () => {
    let start = rowsPerPage * page;
    let end = rowsPerPage * (page+1);
    return employeesData.map((row,i) => {
      if (i>=start && i<end) return <Row key={i} row={row} model={model}/>
    }).filter(e=>e)
  }
  useEffect(()=>{
    retriveData();
  },[]);
  useEffect(()=>{
    if (orderBy) {
      let employeesDataOld = [...employeesData];
      let employeesDataNew = employeesDataOld.sort((a,b)=>{
        if (a[orderBy[0]].toLowerCase() > b[orderBy[0]].toLowerCase()) return orderBy[1] ? 1 : -1;
        else if (a[orderBy[0]].toLowerCase() < b[orderBy[0]].toLowerCase()) return orderBy[1] ? -1 : 1;
        else return 0;
      })
      setemployeesData(employeesDataNew);
    }
  },[orderBy]);
  return (
      <Container maxWidth="md" className={classes.contentTop}>
      <Typography variant="h5" color="textSecondary">
        Perfiles
      </Typography>
      {employeesData.length > 0 &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {model.map((e,i)=>
                    <>
                      {e.required && e.required === 1 && e.def != "privacypolicy" &&
                        <TableCell key={i} onClick={()=>setOrderBy([e.id,!orderBy[1]])} className={classes.pointer}>
                          {e.label}
                          {orderBy[0] === e.id && orderBy[1] && <ArrowDropDownIcon/>}
                          {orderBy[0] === e.id && !orderBy[1] && <ArrowDropUpIcon/>}
                        </TableCell>
                      }
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {employeesData && displayEmployeeData()}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Filas:</span>}
            labelDisplayedRows={({ page }) => `Página ${page+1} de ${Math.ceil(totalRows/rowsPerPage)}`}
          />
        </>
        }
      </Container>
  );

}
