export const phone_numbers = [
  ["AF","Afganistán","Afghanistan","Afghanistan","93"],
  ["AL","Albania","Albania","Albanie","355"],
  ["DE","Alemania","Germany","Allemagne","49"],
  ["AD","Andorra","Andorra","Andorra","376"],
  ["AO","Angola","Angola","Angola","244"],
  ["AI","Anguila","Anguilla","Anguilla","1 264"],
  ["AQ","Antártida","Antarctica","L'Antarctique","672"],
  ["AG","Antigua y Barbuda","Antigua and Barbuda","Antigua et Barbuda","1 268"],
  ["SA","Arabia Saudita","Saudi Arabia","Arabie Saoudite","966"],
  ["DZ","Argelia","Algeria","Algérie","213"],
  ["AR","Argentina","Argentina","Argentine","54"],
  ["AM","Armenia","Armenia","L'Arménie","374"],
  ["AW","Aruba","Aruba","Aruba","297"],
  ["AU","Australia","Australia","Australie","61"],
  ["AT","Austria","Austria","Autriche","43"],
  ["AZ","Azerbaiyán","Azerbaijan","L'Azerbaïdjan","994"],
  ["BE","Bélgica","Belgium","Belgique","32"],
  ["BS","Bahamas","Bahamas","Bahamas","1 242"],
  ["BH","Bahrein","Bahrain","Bahreïn","973"],
  ["BD","Bangladesh","Bangladesh","Bangladesh","880"],
  ["BB","Barbados","Barbados","Barbade","1 246"],
  ["BZ","Belice","Belize","Belize","501"],
  ["BJ","Benín","Benin","Bénin","229"],
  ["BT","Bhután","Bhutan","Le Bhoutan","975"],
  ["BY","Bielorrusia","Belarus","Biélorussie","375"],
  ["MM","Birmania","Myanmar","Myanmar","95"],
  ["BO","Bolivia","Bolivia","Bolivie","591"],
  ["BA","Bosnia y Herzegovina","Bosnia and Herzegovina","Bosnie-Herzégovine","387"],
  ["BW","Botsuana","Botswana","Botswana","267"],
  ["BR","Brasil","Brazil","Brésil","55"],
  ["BN","Brunéi","Brunei","Brunei","673"],
  ["BG","Bulgaria","Bulgaria","Bulgarie","359"],
  ["BF","Burkina Faso","Burkina Faso","Burkina Faso","226"],
  ["BI","Burundi","Burundi","Burundi","257"],
  ["CV","Cabo Verde","Cape Verde","Cap-Vert","238"],
  ["KH","Camboya","Cambodia","Cambodge","855"],
  ["CM","Camerún","Cameroon","Cameroun","237"],
  ["CA","Canadá","Canada","Canada","1"],
  ["TD","Chad","Chad","Tchad","235"],
  ["CL","Chile","Chile","Chili","56"],
  ["CN","China","China","Chine","86"],
  ["CY","Chipre","Cyprus","Chypre","357"],
  ["VA","Ciudad del Vaticano","Vatican City State","Cité du Vatican","39"],
  ["CO","Colombia","Colombia","Colombie","57"],
  ["KM","Comoras","Comoros","Comores","269"],
  ["CG","República del Congo","Republic of the Congo","République du Congo","242"],
  ["CD","República Democrática del Congo","Democratic Republic of the Congo","République démocratique du Congo","243"],
  ["KP","Corea del Norte","North Korea","Corée du Nord","850"],
  ["KR","Corea del Sur","South Korea","Corée du Sud","82"],
  ["CI","Costa de Marfil","Ivory Coast","Côte-d'Ivoire","225"],
  ["CR","Costa Rica","Costa Rica","Costa Rica","506"],
  ["HR","Croacia","Croatia","Croatie","385"],
  ["CU","Cuba","Cuba","Cuba","53"],
  ["CW","Curazao","Curaçao","Curaçao","5999"],
  ["DK","Dinamarca","Denmark","Danemark","45"],
  ["DM","Dominica","Dominica","Dominique","1 767"],
  ["EC","Ecuador","Ecuador","Equateur","593"],
  ["EG","Egipto","Egypt","Egypte","20"],
  ["SV","El Salvador","El Salvador","El Salvador","503"],
  ["AE","Emiratos Árabes Unidos","United Arab Emirates","Emirats Arabes Unis","971"],
  ["ER","Eritrea","Eritrea","Erythrée","291"],
  ["SK","Eslovaquia","Slovakia","Slovaquie","421"],
  ["SI","Eslovenia","Slovenia","Slovénie","386"],
  ["ES","España","Spain","Espagne","34"],
  ["US","Estados Unidos de América","United States of America","États-Unis d'Amérique","1"],
  ["EE","Estonia","Estonia","L'Estonie","372"],
  ["ET","Etiopía","Ethiopia","Ethiopie","251"],
  ["PH","Filipinas","Philippines","Philippines","63"],
  ["FI","Finlandia","Finland","Finlande","358"],
  ["FJ","Fiyi","Fiji","Fidji","679"],
  ["FR","Francia","France","France","33"],
  ["GA","Gabón","Gabon","Gabon","241"],
  ["GM","Gambia","Gambia","Gambie","220"],
  ["GE","Georgia","Georgia","Géorgie","995"],
  ["GH","Ghana","Ghana","Ghana","233"],
  ["GI","Gibraltar","Gibraltar","Gibraltar","350"],
  ["GD","Granada","Grenada","Grenade","1 473"],
  ["GR","Grecia","Greece","Grèce","30"],
  ["GL","Groenlandia","Greenland","Groenland","299"],
  ["GP","Guadalupe","Guadeloupe","Guadeloupe","590"],
  ["GU","Guam","Guam","Guam","1 671"],
  ["GT","Guatemala","Guatemala","Guatemala","502"],
  ["GF","Guayana Francesa","French Guiana","Guyane française","594"],
  ["GG","Guernsey","Guernsey","Guernesey","44"],
  ["GN","Guinea","Guinea","Guinée","224"],
  ["GQ","Guinea Ecuatorial","Equatorial Guinea","Guinée Equatoriale","240"],
  ["GW","Guinea-Bissau","Guinea-Bissau","Guinée-Bissau","245"],
  ["GY","Guyana","Guyana","Guyane","592"],
  ["HT","Haití","Haiti","Haïti","509"],
  ["HN","Honduras","Honduras","Honduras","504"],
  ["HK","Hong kong","Hong Kong","Hong Kong","852"],
  ["HU","Hungría","Hungary","Hongrie","36"],
  ["IN","India","India","Inde","91"],
  ["ID","Indonesia","Indonesia","Indonésie","62"],
  ["IR","Irán","Iran","Iran","98"],
  ["IQ","Irak","Iraq","Irak","964"],
  ["IE","Irlanda","Ireland","Irlande","353"],
  ["BV","Isla Bouvet","Bouvet Island","Bouvet Island",""],
  ["IM","Isla de Man","Isle of Man","Ile de Man","44"],
  ["CX","Isla de Navidad","Christmas Island","Christmas Island","61"],
  ["NF","Isla Norfolk","Norfolk Island","Île de Norfolk","672"],
  ["IS","Islandia","Iceland","Islande","354"],
  ["BM","Islas Bermudas","Bermuda Islands","Bermudes","1 441"],
  ["KY","Islas Caimán","Cayman Islands","Iles Caïmans","1 345"],
  ["CC","Islas Cocos (Keeling)","Cocos (Keeling) Islands","Cocos (Keeling","61"],
  ["CK","Islas Cook","Cook Islands","Iles Cook","682"],
  ["AX","Islas de Åland","Åland Islands","Îles Åland","358"],
  ["FO","Islas Feroe","Faroe Islands","Iles Féro","298"],
  ["GS","Islas Georgias del Sur y Sandwich del Sur","South Georgia and the South Sandwich Islands","Géorgie du Sud et les Îles Sandwich du Sud","500"],
  ["HM","Islas Heard y McDonald","Heard Island and McDonald Islands","Les îles Heard et McDonald",""],
  ["MV","Islas Maldivas","Maldives","Maldives","960"],
  ["FK","Islas Malvinas","Falkland Islands (Malvinas)","Iles Falkland (Malvinas","500"],
  ["MP","Islas Marianas del Norte","Northern Mariana Islands","Iles Mariannes du Nord","1 670"],
  ["MH","Islas Marshall","Marshall Islands","Iles Marshall","692"],
  ["PN","Islas Pitcairn","Pitcairn Islands","Iles Pitcairn","870"],
  ["SB","Islas Salomón","Solomon Islands","Iles Salomon","677"],
  ["TC","Islas Turcas y Caicos","Turks and Caicos Islands","Iles Turques et Caïques","1 649"],
  ["UM","Islas Ultramarinas Menores de Estados Unidos","United States Minor Outlying Islands","États-Unis Îles mineures éloignées","246"],
  ["VG","Islas Vírgenes Británicas","Virgin Islands","Iles Vierges","1 284"],
  ["VI","Islas Vírgenes de los Estados Unidos","United States Virgin Islands","Îles Vierges américaines","1 340"],
  ["IL","Israel","Israel","Israël","972"],
  ["IT","Italia","Italy","Italie","39"],
  ["JM","Jamaica","Jamaica","Jamaïque","1 876"],
  ["JP","Japón","Japan","Japon","81"],
  ["JE","Jersey","Jersey","Maillot","44"],
  ["JO","Jordania","Jordan","Jordan","962"],
  ["KZ","Kazajistán","Kazakhstan","Le Kazakhstan","7"],
  ["KE","Kenia","Kenya","Kenya","254"],
  ["KG","Kirguistán","Kyrgyzstan","Kirghizstan","996"],
  ["KI","Kiribati","Kiribati","Kiribati","686"],
  ["KW","Kuwait","Kuwait","Koweït","965"],
  ["LB","Líbano","Lebanon","Liban","961"],
  ["LA","Laos","Laos","Laos","856"],
  ["LS","Lesoto","Lesotho","Lesotho","266"],
  ["LV","Letonia","Latvia","La Lettonie","371"],
  ["LR","Liberia","Liberia","Liberia","231"],
  ["LY","Libia","Libya","Libye","218"],
  ["LI","Liechtenstein","Liechtenstein","Liechtenstein","423"],
  ["LT","Lituania","Lithuania","La Lituanie","370"],
  ["LU","Luxemburgo","Luxembourg","Luxembourg","352"],
  ["MX","México","Mexico","Mexique","52"],
  ["MC","Mónaco","Monaco","Monaco","377"],
  ["MO","Macao","Macao","Macao","853"],
  ["MK","Macedônia","Macedonia","Macédoine","389"],
  ["MG","Madagascar","Madagascar","Madagascar","261"],
  ["MY","Malasia","Malaysia","Malaisie","60"],
  ["MW","Malawi","Malawi","Malawi","265"],
  ["ML","Mali","Mali","Mali","223"],
  ["MT","Malta","Malta","Malte","356"],
  ["MA","Marruecos","Morocco","Maroc","212"],
  ["MQ","Martinica","Martinique","Martinique","596"],
  ["MU","Mauricio","Mauritius","Iles Maurice","230"],
  ["MR","Mauritania","Mauritania","Mauritanie","222"],
  ["YT","Mayotte","Mayotte","Mayotte","262"],
  ["FM","Micronesia","Estados Federados de","Federados Estados de","691"],
  ["MD","Moldavia","Moldova","Moldavie","373"],
  ["MN","Mongolia","Mongolia","Mongolie","976"],
  ["ME","Montenegro","Montenegro","Monténégro","382"],
  ["MS","Montserrat","Montserrat","Montserrat","1 664"],
  ["MZ","Mozambique","Mozambique","Mozambique","258"],
  ["NA","Namibia","Namibia","Namibie","264"],
  ["NR","Nauru","Nauru","Nauru","674"],
  ["NP","Nepal","Nepal","Népal","977"],
  ["NI","Nicaragua","Nicaragua","Nicaragua","505"],
  ["NE","Niger","Niger","Niger","227"],
  ["NG","Nigeria","Nigeria","Nigeria","234"],
  ["NU","Niue","Niue","Niou","683"],
  ["NO","Noruega","Norway","Norvège","47"],
  ["NC","Nueva Caledonia","New Caledonia","Nouvelle-Calédonie","687"],
  ["NZ","Nueva Zelanda","New Zealand","Nouvelle-Zélande","64"],
  ["OM","Omán","Oman","Oman","968"],
  ["NL","Países Bajos","Netherlands","Pays-Bas","31"],
  ["PK","Pakistán","Pakistan","Pakistan","92"],
  ["PW","Palau","Palau","Palau","680"],
  ["PS","Palestina","Palestine","La Palestine","970"],
  ["PA","Panamá","Panama","Panama","507"],
  ["PG","Papúa Nueva Guinea","Papua New Guinea","Papouasie-Nouvelle-Guinée","675"],
  ["PY","Paraguay","Paraguay","Paraguay","595"],
  ["PE","Perú","Peru","Pérou","51"],
  ["PF","Polinesia Francesa","French Polynesia","Polynésie française","689"],
  ["PL","Polonia","Poland","Pologne","48"],
  ["PT","Portugal","Portugal","Portugal","351"],
  ["PR","Puerto Rico","Puerto Rico","Porto Rico","1"],
  ["QA","Qatar","Qatar","Qatar","974"],
  ["GB","Reino Unido","United Kingdom","Royaume-Uni","44"],
  ["CF","República Centroafricana","Central African Republic","République Centrafricaine","236"],
  ["CZ","República Checa","Czech Republic","République Tchèque","420"],
  ["DO","República Dominicana","Dominican Republic","République Dominicaine","1 809"],
  ["SS","República de Sudán del Sur","South Sudan","Soudan du Sud","211"],
  ["RE","Reunión","Réunion","Réunion","262"],
  ["RW","Ruanda","Rwanda","Rwanda","250"],
  ["RO","Rumanía","Romania","Roumanie","40"],
  ["RU","Rusia","Russia","La Russie","7"],
  ["EH","Sahara Occidental","Western Sahara","Sahara Occidental","212"],
  ["WS","Samoa","Samoa","Samoa","685"],
  ["AS","Samoa Americana","American Samoa","Les Samoa américaines","1 684"],
  ["BL","San Bartolomé","Saint Barthélemy","Saint-Barthélemy","590"],
  ["KN","San Cristóbal y Nieves","Saint Kitts and Nevis","Saint Kitts et Nevis","1 869"],
  ["SM","San Marino","San Marino","San Marino","378"],
  ["MF","San Martín (Francia)","Saint Martin (French part)","Saint-Martin (partie française)","1 599"],
  ["PM","San Pedro y Miquelón","Saint Pierre and Miquelon","Saint-Pierre-et-Miquelon","508"],
  ["VC","San Vicente y las Granadinas","Saint Vincent and the Grenadines","Saint-Vincent et Grenadines","1 784"],
  ["SH","Santa Elena","Ascensión y Tristán de Acuña","Ascensión y Tristan de Acuña","290"],
  ["LC","Santa Lucía","Saint Lucia","Sainte-Lucie","1 758"],
  ["ST","Santo Tomé y Príncipe","Sao Tome and Principe","Sao Tomé et Principe","239"],
  ["SN","Senegal","Senegal","Sénégal","221"],
  ["RS","Serbia","Serbia","Serbie","381"],
  ["SC","Seychelles","Seychelles","Les Seychelles","248"],
  ["SL","Sierra Leona","Sierra Leone","Sierra Leone","232"],
  ["SG","Singapur","Singapore","Singapour","65"],
  ["SX","Sint Maarten","Sint Maarten","Saint-Martin","1 721"],
  ["SY","Siria","Syria","Syrie","963"],
  ["SO","Somalia","Somalia","Somalie","252"],
  ["LK","Sri lanka","Sri Lanka","Sri Lanka","94"],
  ["ZA","Sudáfrica","South Africa","Afrique du Sud","27"],
  ["SD","Sudán","Sudan","Soudan","249"],
  ["SE","Suecia","Sweden","Suède","46"],
  ["CH","Suiza","Switzerland","Suisse","41"],
  ["SR","Surinám","Suriname","Surinam","597"],
  ["SJ","Svalbard y Jan Mayen","Svalbard and Jan Mayen","Svalbard et Jan Mayen","47"],
  ["SZ","Swazilandia","Swaziland","Swaziland","268"],
  ["TJ","Tayikistán","Tajikistan","Le Tadjikistan","992"],
  ["TH","Tailandia","Thailand","Thaïlande","66"],
  ["TW","Taiwán","Taiwan","Taiwan","886"],
  ["TZ","Tanzania","Tanzania","Tanzanie","255"],
  ["IO","Territorio Británico del Océano Índico","British Indian Ocean Territory","Territoire britannique de l'océan Indien","246"],
  ["TF","Territorios Australes y Antárticas Franceses","French Southern Territories","Terres australes françaises",""],
  ["TL","Timor Oriental","East Timor","Timor-Oriental","670"],
  ["TG","Togo","Togo","Togo","228"],
  ["TK","Tokelau","Tokelau","Tokélaou","690"],
  ["TO","Tonga","Tonga","Tonga","676"],
  ["TT","Trinidad y Tobago","Trinidad and Tobago","Trinidad et Tobago","1 868"],
  ["TN","Tunez","Tunisia","Tunisie","216"],
  ["TM","Turkmenistán","Turkmenistan","Le Turkménistan","993"],
  ["TR","Turquía","Turkey","Turquie","90"],
  ["TV","Tuvalu","Tuvalu","Tuvalu","688"],
  ["UA","Ucrania","Ukraine","L'Ukraine","380"],
  ["UG","Uganda","Uganda","Ouganda","256"],
  ["UY","Uruguay","Uruguay","Uruguay","598"],
  ["UZ","Uzbekistán","Uzbekistan","L'Ouzbékistan","998"],
  ["VU","Vanuatu","Vanuatu","Vanuatu","678"],
  ["VE","Venezuela","Venezuela","Venezuela","58"],
  ["VN","Vietnam","Vietnam","Vietnam","84"],
  ["WF","Wallis y Futuna","Wallis and Futuna","Wallis et Futuna","681"],
  ["YE","Yemen","Yemen","Yémen","967"],
  ["DJ","Yibuti","Djibouti","Djibouti","253"],
  ["ZM","Zambia","Zambia","Zambie","260"],
  ["ZW","Zimbabue","Zimbabwe","Zimbabwe","263"]
]
