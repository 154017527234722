import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DoneIcon from '@mui/icons-material/Done';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useStyles } from "./styles";
import {get,post} from "./commons";

export default function WorkReport({biz,shiftsigned,managers,projects,iamamanager,iamadmin,isMobileSize,globalVariables,changeGlobalVariables}) {
  const classes = useStyles();
  const [dataTypes, setDataTypes] = React.useState();
  const [newCategory, setNewCategory] = React.useState();
  const [changeName, setChangeName] = React.useState(-1);
  const [isSaved, setIsSaved] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [wo_filter, setwo_filter] = React.useState();
  const [firstLoad, setFirstLoad] = React.useState(true);
  const fieldTypes = [
    {value:'TEXT', label:"texto"},
    {value:'NUMB', label:"numérico"},
    {value:'DECIM', label:"decimal"},
    {value:'DROPDOWN', label:"selección"},
    {value:'PERCENT', label:"porcentaje"},
    {value:'DATE', label:"fecha"},
    {value:'SUBCODE', label:"subcódigo del empleado"},
    {value:'EDIT_DATE', label:"fecha de edición"},
    {value:'IMAGE', label:"imagen"},
    {value:'GEOLOCATION', label:"geolocalización"}
  ];
  useEffect(()=>{
    get(`/server/request/getprodtypes?all=1`)
    .then((response) => {
      if (response?.data?.length > 0) {
        let newData = response.data.map(e=>{
          let newE = {...e}
          if (e.options) newE.options = JSON.parse(e.options);
          return newE;
        });//console.log(newData)
        setDataTypes(newData);
      }
      if (response?.filters?.length > 0) {
        let newfilters = response.filters.filter(e=>e.content?.length>0);
        if (newfilters.filter(e=>e.name==="edit").length > 0) {
          let prod_menuVal = newfilters.filter(e=>e.name==="edit");
          if (prod_menuVal.length > 0) {
            let parsedVal = JSON.parse(prod_menuVal[0].content);
            if (parsedVal.prod_menu) setFilters(parsedVal.prod_menu);
          }
        }
        if (newfilters.filter(e=>e.name==="wo_filter").length > 0) {
          let prod_menuVal = newfilters.filter(e=>e.name==="wo_filter");
          if (prod_menuVal.length > 0) setwo_filter(prod_menuVal[0].content);
        }
      }
    });
  },[]);
  const gototop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useEffect(()=>{
    if (dataTypes && firstLoad) {
      gototop();
      setFirstLoad(false);
    }
  },[dataTypes]);
  const newFieldType = (event, pos) => {
    let newdataTypes = [...dataTypes];
    newdataTypes[pos].type = event.target.value;
    setDataTypes(newdataTypes);
  }
  const changeMyData = (pos, value) => {
    let newdataTypes = [...dataTypes];
    newdataTypes[pos].wo_item = (value) ? 1 : 0;
    if (value) newdataTypes[pos].wo_visible = 0;
    setDataTypes(newdataTypes);
  }
  const changeMyDataDefault = (pos, value) => {
    let newdataTypes = [...dataTypes];
    newdataTypes[pos].view_default = value;
    setDataTypes(newdataTypes);
  }
  const changeMyDataVisible = (pos, value) => {
    let newdataTypes = [...dataTypes];
    newdataTypes[pos].wo_visible = (value) ? 1 : 0;
    if (value) newdataTypes[pos].wo_item = 0;
    setDataTypes(newdataTypes);
  }
  const editOption = (event, pos, optionPos) => {
    let newdataTypes = [...dataTypes];
    let optionVals = (newdataTypes[pos].options?.values) ? newdataTypes[pos].options : {values:[]};
    optionVals.values[optionPos] = event.target.value;
    newdataTypes[pos].options = optionVals;
    setDataTypes(newdataTypes);
  }
  const addOption = (pos) => {
    let newdataTypes = [...dataTypes];
    newdataTypes[pos].options.values.push(null)
    setDataTypes(newdataTypes);
  }
  const newName = (event, pos) => {
    let newdataTypes = [...dataTypes];
    newdataTypes[pos].name = event.target.value;
    setDataTypes(newdataTypes);
  }
  const newNameCategory = (event, category) => {
    let name = event.target.value;
    let newdataTypes = [...dataTypes].map(e=>{
      e.category = (e.category === category) ? name : e.category;
      return e;
    });
    setChangeName(-1);
    //console.log(newdataTypes);
  }
  const reorderItems = (action, order, position) => {
    let newdataTypes = JSON.stringify(dataTypes);
    let grupNum = Math.floor(position/100);
    let group1 = [], group2 = [], group3 = [], group4 = [];
    if (action === "up") {
      group1 = JSON.parse(newdataTypes)
        .filter(e => e.position < (grupNum-1)*100);
      group3 = JSON.parse(newdataTypes)
        .filter((e) => e.position < grupNum*100 && e.position >= (grupNum-1)*100)
        .map((e) => {
          e.position += 100;
          return e;
        });
      group2 = JSON.parse(newdataTypes)
        .filter((e) => e.position >= grupNum*100 && e.position < (grupNum+1)*100)
        .map((e) => {
          e.position -= 100;
          return e;
        });
      group4 = JSON.parse(newdataTypes)
        .filter(e => e.position >= (grupNum+1)*100);
    }
    else if (action === "down") {
      group1 = JSON.parse(newdataTypes)
        .filter(e => e.position < grupNum*100);
      group3 = JSON.parse(newdataTypes)
        .filter(e => e.position >= grupNum*100 && e.position < (grupNum+1)*100)
        .map(e=>{
          e.position += 100;
          return e;
        });
      group2 = JSON.parse(newdataTypes)
        .filter(e => e.position >= (grupNum+1)*100 && e.position < (grupNum+2)*100)
        .map(e=>{
          e.position -= 100;
          return e;
        });
      group4 = JSON.parse(newdataTypes)
        .filter(e => e.position >= (grupNum+2)*100);
    }
    let groupdataTypes = [...group1,...group2,...group3,...group4];
    setDataTypes(groupdataTypes);
  }
  const deleteItem = (pos, action) => {
    let newdataTypes = [...dataTypes];
    newdataTypes[pos].disabled = action;
    setDataTypes(newdataTypes);
  }
  const addItem = (pos,type) => {
    let newdataTypes = [...dataTypes];
    newdataTypes.splice(pos+1, 0, {
            "category": type.category,
            "position": type.position+1,
            "disabled": 0
        });
    setDataTypes(newdataTypes);
  }
  const addCategory = (event) => {
    let newdataTypes = [...dataTypes];
    newdataTypes.push({
            "category": event.target.value,
            "position": parseInt(Math.ceil(dataTypes[dataTypes.length-1].position/100)*100),
            "disabled": 0
        });
    setDataTypes(newdataTypes);
    setNewCategory("");
  }
  const defaultVal = (event, pos) => {
    let newdataTypes = [...dataTypes];
    newdataTypes[pos].default_value = event.target.value;
    setDataTypes(newdataTypes);
  }
  const storeData = () => {
    post(`/server/request/updateprodtypes`,{data:dataTypes,filters,wo_filter})
    .then((response) => {
      if (response.updated) setIsSaved(true);
    });
  }
  const updatefilters = (e,pos) => {
    let newFilters = [...filters];
    if (e?.target?.value === "*delete*") {
      newFilters.splice(pos, 1);//console.log("newFilters",newFilters)
    }
    else newFilters[pos] = (e?.target?.value) ? e.target.value : "";
    setFilters(newFilters);
  }
  const displayFilters = () => {
    if (filters.length === 0) return (
      <Grid item xs={3}>
        <Select label="filtro 1" onChange={(e)=>setFilters([e.target.value])} fullWidth>
          {dataTypes.map((i,e) => <MenuItem key={e} value={i.id}>{i.category} - {i.name}</MenuItem> )}
        </Select>
      </Grid>
    )
    else {
      let filter_items = filters.map((filter,pos) => {
        return (
         <Grid item xs={3}>
           <FormControl>
             <InputLabel id={`filtro${pos}`}>{`filtro ${pos+1}`}</InputLabel>
             <Select value={filter} onChange={(e)=>updatefilters(e,pos)} fullWidth>
              <MenuItem key={"empty"} defaultValue={null}></MenuItem>
               {dataTypes.map((i,e) => <MenuItem key={e} value={i.id}>{i.category} - {i.name}</MenuItem> )}
              <MenuItem key={"delete"} value="*delete*"><i>eliminar</i></MenuItem>
             </Select>
           </FormControl>
         </Grid>
       )
     });
     filter_items.push(
        <Grid item xs={3}>
          <AddIcon onClick={(e)=>updatefilters(null,filters.length)}/>
        </Grid>
     );
     return filter_items;
   }
  }
  const displayTypes = () => {
    let allCategories = dataTypes?.filter((type,pos)=>(pos===0 || type.category !== dataTypes[pos-1].category)).map(e=>e.category);
    return dataTypes?.map((type, pos)=>{
      let display = []
      if (pos === 0 || type.category !== dataTypes[pos-1].category) {
        display.push(
          <Grid item xs={6}>
            {type.position !== changeName &&
              <>
                <strong>{type.category}</strong>
                <IconButton key={"editIcon"+type.position} variant="contained" onClick={()=>setChangeName(type.position)}>
                   <EditIcon/>
                </IconButton>
                {pos > 0 &&
                   <IconButton key={"arrowup"+type.position} variant="contained" onClick={()=>reorderItems("up",pos,type.position)}>
                      <ArrowDropUpIcon/>
                   </IconButton>
                  }
                {type.category !== allCategories[allCategories.length-1] &&
                   <IconButton key={"arrowdown"+type.position} variant="contained" onClick={()=>reorderItems("down",pos,type.position)}>
                      <ArrowDropDownIcon/>
                   </IconButton>
                 }
              </>
            }
            {type.position === changeName &&
              <TextField id={"changecategory"+type.position} variant="outlined" defaultValue={type.category} onBlur={(e)=>newNameCategory(e,type.category)} fullWidth/>
            }
          </Grid> )
        if (pos === 0) display.push( <Grid item xs={3}/> ,<Grid item xs={1}> Visible al operador </Grid>, <Grid item xs={1}> Mostrar por defecto </Grid>, <Grid item xs={1}/> )
        else display.push( <Grid item xs={6}/> )
      }
      display.push(
        <>
          <Grid item xs={1}/>
          <Grid item xs={3}>
            <TextField id="default-options" label={"nombre"} variant="outlined" value={type.name} onChange={(e)=>newName(e,pos)} disabled={type.disabled}/>
          </Grid>
          <Grid item xs={2}>
            <Select value={type.type} onChange={(e)=>newFieldType(e,pos)} disabled={type.disabled} fullWidth>
              {fieldTypes.map((i,e) => <MenuItem key={e} value={i.value}>{i.label}</MenuItem> )}
            </Select>
          </Grid>
          <Grid item xs={3}>
            {!['DROPDOWN','SUBCODE','EDIT_DATE'].includes(type.type) &&
              <TextField id="default-options" label={"valor por defecto"} value={type.default_value} onChange={(e)=>defaultVal(e,pos)} variant="outlined" disabled={type.disabled}/>
            }
            {['SUBCODE','EDIT_DATE'].includes(type.type) &&
            <>
              <Select value={type.options?.values ? type.options?.values[0] : null} onChange={(e)=>editOption(e,pos,0)} disabled={type.disabled} fullWidth>
                {dataTypes.filter(e=>e.wo_item).map((i,e) => <MenuItem key={e} value={i.id}>{i.name}</MenuItem> )}
              </Select>
              </>
            }
            {type.type === 'DROPDOWN' &&
            <>
             {!type.options?.values &&
               <TextField id={"option0"} label={"opción 1"} variant="outlined" onChange={(e)=>editOption(e,pos,0)} disabled={type.disabled}/>
             }
             {type.options?.values?.map((f,j)=>
                <>
                  <TextField id={"option"+j} label={"opción " + (j+1)} variant="outlined" value={f} onChange={(e)=>editOption(e,pos,j)} autoFocus={j===0} disabled={type.disabled}/>
                </>
              )}
              {type.options?.values &&
                <AddIcon onClick={()=>addOption(pos)}/>
              }
              </>
            }
          </Grid>
          <Grid item xs={1}>
            { !['SUBCODE','EDIT_DATE'].includes(type.type) &&
              <>
                <IconButton key={pos+"EditIcon"} variant="contained">
                  {Boolean(type.wo_item) &&
                    <EditIcon onClick={(e)=>changeMyData(pos,false)} className={classes.blue}/>
                  }
                  {!Boolean(type.wo_item) &&
                    <EditOffIcon onClick={(e)=>changeMyData(pos,true)}/>
                  }
                </IconButton>
                <IconButton key={pos+"VisibilityIcon"} variant="contained">
                  {Boolean(type.wo_visible) &&
                    <VisibilityIcon onClick={(e)=>changeMyDataVisible(pos,false)} className={classes.blue}/>
                  }
                  {!Boolean(type.wo_visible) &&
                    <VisibilityOffIcon onClick={(e)=>changeMyDataVisible(pos,true)}/>
                  }
                </IconButton>
              </>
            }
          </Grid>
          <Grid item xs={1}>
            <IconButton key={pos+"DefaultIcon"} variant="contained">
              {Boolean(type.view_default) &&
                <CheckBoxIcon onClick={()=>changeMyDataDefault(pos,0)}/>
              }
              {!Boolean(type.view_default) &&
                <CheckBoxOutlineBlankIcon onClick={()=>changeMyDataDefault(pos,1)}/>
              }
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton key={pos+"EditIcon"} variant="contained">
              {!Boolean(type.disabled) &&
                <DeleteIcon onClick={()=>deleteItem(pos,1)}/>
              }
              {Boolean(type.disabled) &&
                <RestoreFromTrashIcon onClick={()=>deleteItem(pos,0)}/>
              }
            </IconButton>
            {(pos+1 === dataTypes.length || (pos+1 < dataTypes.length && type.category !== dataTypes[pos+1].category)) &&
              <IconButton key={pos+"BottomIcon"} variant="contained">
                <AddIcon onClick={()=>addItem(pos,type)}/>
              </IconButton>
            }
          </Grid>
        </>
        )
      return display
    })
  }
  if (dataTypes) return (
    <>
      <Container className={classes.contentTop}>
        <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={2}>
          <Grid item xs={11}/>
          <Grid item xs={1} className={classes.storeButton}>
            <Button key="send" variant="contained" onClick={storeData}>Guardar</Button>
          </Grid>
          {displayTypes()}
          <Grid item xs={3}>
            <TextField id={"addcategories"} label={"nueva categoria"} variant="outlined" value={newCategory} onChange={(e)=>setNewCategory(e.target.value)} onBlur={(e)=>addCategory(e)}/>
          </Grid>
          <Grid item xs={9}>
            <AddIcon/>
          </Grid>
          <Grid item xs={12}>
            <strong>Filtrado por</strong>
          </Grid>
          {displayFilters()}
          <Grid item xs={12}>
            <strong>Filtro adicional en Partes de Trabajo</strong>
          </Grid>
          <Grid item xs={12}>
            <Select value={wo_filter} label="filtro wo" onChange={(e)=>setwo_filter(e.target.value)} fullWidth>
              <MenuItem key="empty" value=""/>
              {dataTypes.map((i,e) => <MenuItem key={e} value={i.id}>{i.category} - {i.name}</MenuItem> )}
            </Select>
          </Grid>
          <Grid item xs={11}/>
          <Grid item xs={1} className={classes.storeButton}>
            <Button key="send" variant="contained" onClick={gototop}><ArrowUpwardIcon/></Button>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={isSaved} autoHideDuration={4500} onClose={()=>setIsSaved(false)}>
        <MuiAlert elevation={6} variant="filled"  onClose={()=>setIsSaved(false)} severity="success" sx={{ width: '100%' }}>
          Guardado!
        </MuiAlert>
      </Snackbar>
    </>
  );
  else return (
    <Container className={classes.contentTop}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
      Cargando datos...
    </Container>
  )

}
