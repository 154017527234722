
import React, { Component, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import { useStyles } from "./styles";
import {post} from "./commons";
export default function Contactus() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [name, setname] = React.useState();
  const [surname, setsurname] = React.useState();
  const [phone, setphone] = React.useState();
  const [email, setemail] = React.useState();
  const [company, setcompany] = React.useState();
  const [thanks, setthanks] = React.useState(false);

  const recaptcha = '6LcMkncgAAAAALL396p5slDGQgX28tSZPxYR2FdF';
  function readyRecaptcha(id) {//console.log("rectp",id)
    window.grecaptcha.render('recaptcha', {
          'sitekey' : recaptcha,
          'theme' : 'light',
          'size': 'invisible'
        })
    window.grecaptcha.execute() //.then(function(token) { console.log("low",token) });
  }
  useEffect(()=>{
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (isScriptExist && callback) callback();
    }
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=explicit&hl=es`, function () {
      if (window.grecaptcha)
        window.grecaptcha.ready(() => readyRecaptcha(1));
    });
  },[])

  const send = () => {
    let token = (window.grecaptcha) ? window.grecaptcha.getResponse() : null;
    if (checked && name && surname && phone && email && company && token) {
      post('/server/contact_request',{data:{n:name,s:surname,t:phone,e:email,c:company},token:token}).then((response)=>{
        if (response.ok) setthanks(true);
      });
    }
    }


    return (
      <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
        <Grid item lg={12} id="top" className={classes.aligncenter}>
          <Typography variant="h4">
             Contacta con nosotros
          </Typography>
          <Typography variant="body1">
            Completa este formulario con tus datos y nos pondremos en contacto contigo para asesorarte sobre cómo podemos ayudarte a mejorar la productividad de tu equipo y tu empresa.
          </Typography>
        </Grid>
        <Grid item lg={12}>&nbsp;</Grid>
        <Grid item lg={6} id="subtop1">
          <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
            <Grid item xs={12} id="subtop1">
              Disponemos de varios canales de comunicación para que puedas contactar con nosotros del modo que prefieras:
            </Grid>
            <Grid item xs={4}>
              <a href="https://x.com/Workload_agency" target="_X" className={classes.nodecor}>
                <div className={classes.icon_size}>
                  <svg viewBox="0 0 24 24" aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-18jsvk2 r-16y2uox r-8kz0gk"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
                </div>
              </a>
            </Grid>
            <Grid item xs={8}>
              <a href="https://x.com/Workload_agency" target="_X" className={classes.nodecor}>
                  @Workload_agency
              </a>
            </Grid>
            <Grid item xs={4}>
              <a href="mailto:workload@zyrkia.com" className={classes.nodecor}>
                <MailOutlineIcon/>
              </a>
            </Grid>
            <Grid item xs={8}>
              <a href="mailto:workload@zyrkia.com" className={classes.nodecor}>
                  workload@zyrkia.com
              </a>
            </Grid>
            <Grid item xs={4}>
              <a href="tel:+34650685558" className={classes.nodecor}>
                <LocalPhoneIcon/>
              </a>
            </Grid>
            <Grid item xs={8}>
              <a href="tel:+34650685558" className={classes.nodecor}>
                  +34 650 685 558
              </a>
            </Grid>

          </Grid>
        </Grid>
        <Grid item lg={6} id="subtop1">
          <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
            <Grid item xs={12} id="subtop2">
              <Typography variant="h6">
                ¿Hablamos?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.padding8}>
              <TextField id="outlined-nombre" fullWidth label="nombre*" variant="outlined" onBlur={(e)=>setname(e.target.value)}/>
            </Grid>
            <Grid item xs={12} className={classes.padding8}>
              <TextField id="outlined-apellidos" fullWidth label="apellidos*" variant="outlined" onBlur={(e)=>setsurname(e.target.value)}/>
            </Grid>
            <Grid item xs={12} className={classes.padding8}>
              <TextField id="outlined-tlf" fullWidth label="Teléfono*" variant="outlined"onBlur={(e)=>setphone(e.target.value)} />
            </Grid>
            <Grid item xs={12} className={classes.padding8}>
              <TextField id="outlined-email" fullWidth label="email*" variant="outlined" onBlur={(e)=>setemail(e.target.value)}/>
            </Grid>
            <Grid item xs={12} className={classes.padding8}>
              <TextField id="outlined-empresa" fullWidth label="empresa*" variant="outlined" onBlur={(e)=>setcompany(e.target.value)}/>
            </Grid>
            <Grid item xs={12} id="recaptcha"/>
            <Grid item xs={12} className={classes.padding8}>
              <Checkbox
                checked={checked}
                onChange={()=>setChecked(!checked)}
              />
              Acepto el tratamiento de mis datos personales conforme a las condiciones establecidas en la política de privacidad
            </Grid>
            <Grid item xs={12} className={classes.padding8}>
              <Button
                onClick={send}
                variant="text"
                disabled={!checked || !name || !surname || !phone || !email || !company}
                >enviar</Button>
                {thanks && <p>Gracias, nos pondremos en contacto contigo a la mayor brevedad posible.</p>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
