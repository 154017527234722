import React, { useEffect } from 'react';

import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PeopleIcon from '@mui/icons-material/People';

import { useStyles } from "./styles";
import {get, post} from "./commons";

export default function WorkReport({biz,shiftsigned,managers,projects}) {
  const classes = useStyles();
  const [totalRows, setTotalRows] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [clientsData, setClientsData] = React.useState([]);
  const [clientsDataFiltered, setClientsDataFiltered] = React.useState([]);
  const [displayClientsData, setDisplayClientsData] = React.useState([]);
  const [order, setOrder] = React.useState({});
  const [clientEmployees, setclientEmployees] = React.useState([]);
  const [model, setModel] = React.useState();
  const [isSaved, setisSaved] = React.useState(false);

  const sorting = (items,field) => {
    let newOrder = {...order}
    newOrder[field] = (newOrder[field]) ? !newOrder[field] : true;
    setOrder(newOrder);
    return items.sort(function(a, b) {
      let alower = (a[field]) ? a[field].toLowerCase() : null;
      let blower = (b[field]) ? b[field].toLowerCase() : null;
      if (alower && blower) {
        if (newOrder[field]) {
          if (alower > blower) return 1;
          if (alower < blower) return -1;
        }
        else {
            if (alower < blower) return 1;
            if (alower > blower) return -1;
          }
      }
      return 0;
    });
  }

  const retriveDataDB = () => {
    get(`/server/request/getclientsfullset`).then((response)=>{
        if (response.clients) {
          let allClients = sorting(response.clients,"name");
          setClientsData(allClients);//console.log(allClients)
          setClientsDataFiltered(allClients);
          setDisplayClientsData(allClients.slice(0,rowsPerPage));
          setTotalRows(response.clients.length);
        }
        if (response.model && response.model[0] && response.model[0].model) {
          let modelJSON = JSON.parse(response.model[0].model);
          if (modelJSON) {
            setModel(modelJSON);
          }
        }
    });
  }

  useEffect(()=>{
    retriveDataDB()
  },[]);

  const reorder = (field) => {
    let allClients = sorting(clientsDataFiltered,field);
    setClientsDataFiltered(allClients);
    setPage(0);
    setDisplayClientsData(allClients.slice(0,rowsPerPage));
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    let start = newPage*rowsPerPage;
    setDisplayClientsData(clientsDataFiltered.slice(start,newPage*rowsPerPage+rowsPerPage));
  };
  const handleChangeRowsPerPage = (event) => {
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
    setDisplayClientsData(clientsDataFiltered.slice(0,rows));
  };
  const showEmployees = (row,index) => {
    if (row.code)
      get(`/server/request/getemployees4client?id=${row.code}`).then((response)=>{
        if (response.employees) {
          let newclientEmployees = [];
          let hours = response.employees.reduce((a,c)=>{
            a += c.hours;
            return a;
          },0);//console.log("hours",hours)
          newclientEmployees[index] = {employees:response.employees,total:hours};
          setclientEmployees(newclientEmployees);
        }
      });
  }
  const filterData = (text) => {
    let filteredclientsData = (text) ? clientsData.filter((e)=>{
      if (e.name && e.name.toLowerCase().indexOf(text.toLowerCase())>-1) return true;
      else if (e.code && e.code.indexOf(text)>-1) return true;
      else return false;
    }) : clientsData;
    setTotalRows(filteredclientsData.length);
    setPage(0);
    setClientsDataFiltered(filteredclientsData);
    setDisplayClientsData(filteredclientsData.slice(0,rowsPerPage));
  };
  const selectFile = (event) => {
    var reader = new FileReader();
    reader.onload = function(){
      let text = reader.result.replace(/\r/gi,"");
      if (model.divider) {
        let rows = text.split("\n");
        let headers = [];
        if (!model.header) {
          headers = model.references.map((header)=>{
            if (header.db) return header.db;
            else return null;
          })
        }
        let newData = rows.map((e,i)=>{
          if (model.header && i===0) {
            headers = e.split(model.divider).map((e)=>{
              let val = model.references.filter((f)=>f.id===e);
              if (val[0] && val[0].db) return val[0].db;
              else return null;
            })
            return null;
          }
          else {
            let rowData = e.split(model.divider);
            if (rowData.length === headers.length) {
              let newVal = {}
              headers.forEach((e,i)=>{//console.log(e,i,newVal[e],rowData[i])
                if (e) newVal[e] = rowData[i];
              })
              return newVal
            }
            return null;
          }
        }).filter(n=>n);
        if (newData) {
          post(`/server/request/setclients`,{newData}).then((response)=>{
            if (response.updated) {
              retriveDataDB();
              setisSaved(true)
            }
          });
        }
      }
    };
    reader.readAsText(event.target.files[0],'ISO-8859-1');
  }
  return (
    <Container maxWidth="md" className={classes.contentTop}>
      <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            Clientes
          </Typography>
        </Grid>
        <Grid item xs={6} id="search">
          <FormControl variant="standard">
            <InputLabel htmlFor="search">
              Buscar
            </InputLabel>
            <Input
              id="search"
              autoFocus
              onChange={(e)=>filterData(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        {model && model.divider &&
          <Grid item xs={6} id="load" className={classes.end}>
            <input
              accept='text/txt'
              className={classes.nodisplay}
              id="uploaDOC"
              multiple
              type="file"
              name="csvclients"
              onChange={(e)=>{selectFile(e)}}
            />
            <label htmlFor="uploaDOC" >
              <Button
                variant="outlined"
                startIcon={<UploadIcon/>}
                size="small"
                component="span"
                className={classes.button1}
              > Subir fichero</Button>
            </label>
          </Grid>
          }
      </Grid>
      {displayClientsData.length > 0 &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell key="name" className={classes.strongpointer} onClick={()=>reorder("name")}>Nombre</TableCell>
                  <TableCell key="code" className={classes.strongpointer} onClick={()=>reorder("code")} align="right">Código</TableCell>
                  <TableCell key="projects" className={classes.strongpointer} align="center">Empleados asociados</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayClientsData.map((e,i) => (
                  <>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                      <>
                        <TableCell key={"name"+i}>{e.name}</TableCell>
                        <TableCell key={"code"+i} align="center">{e.code}</TableCell>
                        <TableCell key={"projects"+i} align="center">
                        {e.code &&
                          <PeopleIcon onClick={()=>showEmployees(e,i)} className={classes.strongpointer}/>
                          }
                        </TableCell>
                      </>
                    </TableRow>
                    {(clientEmployees[i] && !isNaN(clientEmployees[i].total)) &&
                      <TableRow>
                        <TableCell colSpan={3}>
                          <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell key="projects" className={classes.strongpointer}>Empleado</TableCell>
                                  <TableCell key="horas" className={classes.strongpointer} align="center">Horas totales</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {clientEmployees[i].employees && clientEmployees[i].employees.map((f,j) =>
                                  <TableRow>
                                      <TableCell key={"name"+j}>{f.name}</TableCell>
                                      <TableCell key={"hours"+j} align="right">{f.hours}</TableCell>
                                  </TableRow>
                                )}
                                <TableRow>
                                    <TableCell key="totaltext">TOTAL</TableCell>
                                    <TableCell key="totalnum" align="right">{clientEmployees[i] && clientEmployees[i].total}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    }
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Filas:</span>}
            labelDisplayedRows={({ page }) => `Página ${page+1} de ${Math.ceil(totalRows/rowsPerPage)}`}
          />
        </>
        }
        <Snackbar open={isSaved} autoHideDuration={6000} onClose={()=>setisSaved(false)}>
          <MuiAlert elevation={6} variant="filled"  onClose={()=>setisSaved(false)} severity="success" sx={{ width: '100%' }}>
            Guardado!
          </MuiAlert>
        </Snackbar>
      </Container>
  );

}
