import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EditIcon from '@mui/icons-material/Edit';
import ExcellentExport from 'excellentexport';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditOffIcon from '@mui/icons-material/EditOff';

import { useStyles } from "./styles";
import {get, post} from "./commons";

function Row({ index,row,managers,projects,employees,employee,client,workOrder,manager,go2date,leaveModel,keyVal,allShifts,allDiffShifts }) {
  const classes = useStyles();
  const [displayTimings, setDisplayTimings] = React.useState(false);

  const displayDate = (date) => {
    let newDate = new Date(date);
    if (newDate) return newDate.toLocaleDateString('es-ES')
  }

  const showemployeename = (row) => {
    if (row.employee && employees) {console.log("showemployeename row",row,employees)
      let emp = employees.filter((e)=>e.code===row.employee);employees.map((e)=>console.log(e.code,row.employee));
      if (emp && emp[0] && emp[0].name) return emp[0].name;
    }
  }
  const approvalText = (n) =>{
    if (n.ds_status === 0) return <EditOffIcon titleAccess="Sin firmar"/>;
    else if (n.ds_status === 1 && n.dsm_status > 2) return <HourglassFullIcon titleAccess="Aprobado"/>;
    else if (n.ds_status === 1) return <HourglassEmptyIcon titleAccess="Pendiente"/>;
    else if (n.ds_status === 2) return <CloseIcon titleAccess="Rechazado"/>;
    else if (n.ds_status === 3) return <CheckIcon titleAccess="Aprobado"/>;
    else if (n.ds_status === 4) return <DoneAllIcon titleAccess="Aprobado por administración"/>;
  }
  const displayPaidLeaveTypeName = (type) => {
    if (leaveModel && leaveModel.types) {
      let typeData = leaveModel.types.filter((e)=>e.def === type);
      if (typeData[0] && typeData[0].name) return typeData[0].name;
    }
  }
  const displayTime = (t,t2) => {
    if (t) {
      let timeSet = new Date(t);
      let timeSet2 = (t2) ? new Date(t2) : null;
      let h = timeSet.getHours();
      let m = timeSet.getMinutes();
      if (!t2) {
        if (h<10) h = `0${h}`;
        if (m<10) m = `0${m}`;
        return `${h}:${m}`;
      }
      else if (t && t2) {
        let h2 = timeSet2.getHours();
        let m2 = timeSet2.getMinutes();
        let tH1 = h2*60 + m2;
        let tH2 = h*60 + m;
        let newT = tH2 - tH1;
        let newH = Math.floor(newT/60);
        let newM = newT - (newH *60);
        if (newH<10) newH = `0${newH}`;
        if (newM<10) newM = `0${newM}`;
        return `${newH}:${newM}`;
      }
    }
  }
  const displayAllTimings = (timings) => {
    let t = JSON.parse(row.timings);
    if (t?.times) {
      return t.times.map((e,num)=>{
        return <>
          <Grid item xs={1}>
            {`(${num})`}
          </Grid>
          <Grid item xs={2}>
            {e.start && <>Comienzo: {displayTime(e.start)}</>}
            {e.starttrack && !e.manualstart &&
              <a target="_map" className={classes.nodecor} href={`https://www.google.com/maps/search/?api=1&query=${e.starttrack.lat}%2C${e.starttrack.long}`}>
                <LocationOnIcon  className={classes.editedTime}/>
              </a>
            }
          </Grid>
          <Grid item xs={2}>
            {e.end && <>Fin: {displayTime(e.end)}</>}
            {e.endtrack && !e.manualend &&
              <a target="_map" className={classes.nodecor} href={`https://www.google.com/maps/search/?api=1&query=${e.endtrack.lat}%2C${e.endtrack.long}`}>
                <LocationOnIcon  className={classes.editedTime}/>
              </a>
            }
          </Grid>
          <Grid item xs={1}>
            {e.start && e.end && displayTime(e.end,e.start)}
          </Grid>
          {(e.manualstart || e.manualend) &&
            <>
              <Grid item xs={1}/>
              <Grid item xs={2} className={classes.editedTime}>
                {e.manualstart &&
                  <>
                    <EditIcon className={classes.editedTime}/>
                    {(new Date(e.manualstart)).toLocaleString()}
                  </>
                }
              </Grid>
              <Grid item xs={2} className={classes.editedTime}>
                {e.manualend &&
                  <>
                    <EditIcon className={classes.editedTime}/>
                    {(new Date(e.manualend)).toLocaleString()}
                  </>
                }
              </Grid>
              <Grid item xs={1}/>
            </>
          }
        </>
      })
    }
  }
  const showTimings = (row) => {
    let t = JSON.parse(row.timings);
    let empty = false;////console.log("row.timings",row.timings,t)
    if (t?.times || (row.time_started && row.time_finished)) {
      let equalRows = allShifts.filter((e) => row.employee === e.employee && row.date === e.date);
      if (equalRows.length > 1) {
        let equalRowsPos = equalRows.reduce((a,c,i) => {if (row === c) a = i;return a}, null);
        if (equalRowsPos > 0) return;
      }
      let min;
      if (t?.times) {
        let totalT = t.times.reduce((a,c)=>{
          if (c.start && c.end) a+= c.end - c.start;
          return a;
        },0);
        min = Math.floor(totalT/1000/60);
      }
      else if (row.time_started && row.time_finished) {
        let startT = row.time_started.split(":").map(e=>parseInt(e));
        let endT = row.time_finished.split(":").map(e=>parseInt(e));
        if (startT.length > 1 && endT.length > 1) {
          min = (endT[0]*60 + endT[1]) - (startT[0]*60 + startT[1]);
        }
      }
      if (Number.isInteger(min)) {
        let hoursTotals = min/60;
        let hours = (min >= 60) ? Math.floor(min/60) : 0;
        let minutes = min - hours * 60;
        if (minutes<10) minutes = `0${minutes}`;
        let newT = JSON.parse(row.timings);
        let manual = (newT?.times) ? newT.times.filter((f)=>f.manualstart || f.endstart)?.length > 0 : false;
        //console.log("row",newT)
        return (
        <TableCell
          align="center"
          key="5d"
          rowSpan={equalRows.length}>
          <span
            onClick={()=>setDisplayTimings(row)}
            className={(row.worked_hours >= hoursTotals-0.5 && row.worked_hours <= hoursTotals+0.25 && !manual) ? classes.bluepointer : classes.redpointer}
          >{` ${hours}:${minutes}`}</span>
        </TableCell>
        )
      }
      else empty = true;
    }
    else empty = true;
    if (empty) return <TableCell/>
  }
  const displayTotalHours2Time = (hours) => {
    if (typeof hours === "number") {
      let h_split = Math.trunc(hours);
      let m_split = (hours - Math.floor(hours)) * 60;
      let h = (h_split<10) ? `0${h_split}` : h_split;
      let m = (!m_split) ? "00" : (m_split<10) ? `0${m_split}` : m_split;//console.log(h_split,m_split,h,m)
      return `${h}:${m}`;
    }
  }
  const displayClassNameRow = (row) => {
    if (allDiffShifts) {
      //console.log(allDiffShifts)
      let classVal = allDiffShifts.reduce((a,c,i) => {
        if (c.employee === row.employee && c.date === row.date && i % 2 == 0) a = classes.coloredRows;
        return a;
      },null);
      return classVal;
    }
  }
  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        key={keyVal}
        className={displayClassNameRow(row)}
        >
        <TableCell key="1"> {displayDate(row.date)} </TableCell>
        {!client &&
          <TableCell key="2"> {row.client} </TableCell>
        }
        {!workOrder &&
          <TableCell key="3"> {row.working_order} </TableCell>
        }
        {!employee &&
          <TableCell key="4"> {showemployeename(row)} </TableCell>
        }
          {Boolean(row.holidays) &&
            <TableCell align="center" key="5">
              <BeachAccessIcon titleAccess="Vacaciones"/>
            </TableCell>
          }
          {row.paidleave !== "0" &&
            <TableCell align="center" key="5b">
              <EventBusyIcon titleAccess="Permiso retribuido"/>
              <br/>
              {displayPaidLeaveTypeName(row.paidleave)}
            </TableCell>
          }
          {!Boolean(row.holidays) && row.paidleave === "0" &&
            <TableCell align="center" key="5c" onClick={()=>setDisplayTimings(row)}>
              {row.wo_hours}
            </TableCell>
          }
          {showTimings(row)}
        <TableCell align="center" key="8"> {approvalText(row)} </TableCell>
        <TableCell align="center" key="9"><EditIcon onClick={()=>go2date(row.date,row.employee,false,showemployeename(row))} className={classes.strongpointer}/></TableCell>
      </TableRow>
      <Dialog open={Boolean(displayTimings)} onClose={()=>setDisplayTimings(false)}>
        <DialogTitle>Horas Trabajadas</DialogTitle>
        <DialogContent>
        <Grid container spacing={1} columns={6}>
          <Grid item xs={5}>
            Total ordenes de trabajo:
          </Grid>
          <Grid item xs={1}>
            {displayTotalHours2Time(row.worked_hours)}
          </Grid>
          {displayTimings.time_started &&
              <>
              <Grid item xs={5}>
                Comienzo:
              </Grid>
              <Grid item xs={1}>
                {displayTimings.time_started}
              </Grid>
            </>
          }
          {displayTimings.time_finished &&
              <>
                <Grid item xs={5}>
                  Fin
                </Grid>
                <Grid item xs={1}>
                  {displayTimings.time_finished}
                </Grid>
            </>
          }
          {displayTimings.timings && displayAllTimings(displayTimings.timings)}
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>setDisplayTimings(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default function WorkReport({biz,shiftsigned,managers,projects,iamamanager,iamadmin,isMobileSize,go2date,globalVariables,changeGlobalVariables}) {
  const classes = useStyles();

  const [haveData, setHaveData] = React.useState(false);
  const [totalRows, setTotalRows] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = React.useState((globalVariables && globalVariables.allshifts_employee) ? globalVariables.allshifts_employee : null);
  const [clients, setClients] = React.useState([]);
  const [client, setClient] = React.useState((globalVariables && globalVariables.allshifts_client) ? globalVariables.allshifts_client : null);
  const [workOrders, setWorkOrders] = React.useState([]);
  const [workOrder, setWorkOrder] = React.useState((globalVariables && globalVariables.allshifts_workOrder) ? globalVariables.allshifts_workOrder : null);
  const [manager, setManager] = React.useState((globalVariables && globalVariables.allshifts_manager) ? globalVariables.allshifts_manager : null);
  const [approved, setapproved] = React.useState((globalVariables && globalVariables.allshifts_approved) ? globalVariables.allshifts_approved : "no");
  const [allShifts, setAllShifts] = React.useState();
  const [allDiffShifts, setAllDiffShifts] = React.useState();
  const [haveMissingWO, setHaveMissingWO] = React.useState(false);
  const [missingWO, setMissingWO] = React.useState();
  const [holidayActions, setholidayActions] = React.useState([]);
  const [leaveActions, setleaveActions] = React.useState([]);
  const [isNotified, setisNotified] = React.useState();
  const [worked, setWorked] = React.useState((globalVariables && globalVariables.allshifts_worked) ? globalVariables.allshifts_worked : 1);
  const [leaveModel, setLeaveModel] = React.useState();
  const [workHoursModel, setWorkHoursModel] = React.useState();

  const displayDate = (date) => {
    let newDate = new Date(date);
    if (newDate) return newDate.toLocaleDateString('es-ES')
  }

  const padLeft = (n) => ("00" + n).slice(-2);
  const today = new Date();
  const todayFormated = today.getFullYear() + "-" + padLeft(1+today.getMonth()) + "-" + padLeft(today.getDate());
  const [startDate, setStartDate] = React.useState((globalVariables && globalVariables.allshifts_startDate) ? globalVariables.allshifts_startDate : todayFormated);
  const [endDate, setEndDate] = React.useState((globalVariables && globalVariables.allshifts_endDate) ? globalVariables.allshifts_endDate : todayFormated);

/*  const currentTime = () =>{
    var d = new Date();
    var hr = d.getHours();
    var min = d.getMinutes();
    if (hr < 10) hr = `0${hr}`
    return `${hr}:${min}`
  }*/
  const getStatus = (val) => {
    if (val === 4) return "aprobado admin"
    else if (val === 3) return "aprobado"
    else if (val === 2) return "rechazado"
    else if (val === 1) return "pendiente"
    else return ""
  }
  const getBoolean = (val) => {
    if (val === 1) return "si"
    else return "no"
  }
  const getVal = (val) => {
    if (val && val != null) return val
    else return ""
  }
  const getTime = (t) => {
    if (t) {
      let timeSet = new Date(t);
      let h = timeSet.getHours();
      if (h<10) h = `0${h}`;
      let m = timeSet.getMinutes();
      if (m<10) m = `0${m}`;
      return `${h}:${m}`;
    }
    else return ""
  }
  const getValAction = (val) => {
    if (workHoursModel && workHoursModel.elements && val && val != null) {
      let elementsItems = (val.description) ? [val.description] : [""];
      for (var i=0; i<workHoursModel.elements.length; i++){
        if (workHoursModel.elements[i].id && val[workHoursModel.elements[i].id]) {
          if (workHoursModel.elements[i].def === "dailyhours") {
            let allHrs = val[workHoursModel.elements[i].id];
            let allKeys = Object.keys(allHrs);
            let totalhoursperwo = allKeys.reduce((a,c)=>{
              a += allHrs[c];
              return a;
            },0);
            elementsItems.push(totalhoursperwo);
            for (var j=0; j<workHoursModel.elements[i].multipleoptions.length; j++) elementsItems.push(allHrs[workHoursModel.elements[i].multipleoptions[j]]);
          }
          else if (workHoursModel.elements[i].type !== "checkbox") elementsItems.push(val[workHoursModel.elements[i].id]);
          else {
            if (val[workHoursModel.elements[i].id]) elementsItems.push("SI")
            else elementsItems.push("")
          }
        }
        else elementsItems.push("")
      }
      return elementsItems
    }
    else return ""
  }
  const getWOstate = (val) => {
    if (val === 0) return "no creados"
    else if (val === 1) return "pendientes de revisión"
    else if (val === 2) return "rechazados"
    else if (val === 3) return "aprobados"
    else if (val === 4) return "todos"
  }
  const getSingleWOstate = (val) => {
    if (val === 1) return "pendiente"
    else if (val === 2) return "rechazado"
    else if (val === 3) return "aprobado"
    else if (val === 4) return "aprobado admin"
    else return ""
  }
  const getLeaveType = (type) => {
    if (leaveModel && leaveModel.types) {
      let typeData = leaveModel.types.filter((e)=>e.def === type);
      if (typeData[0] && typeData[0].name) return typeData[0].name;
      else return "no"
    }
  }

  const selectClient = (event, newValue) => {
    setClient(newValue);
    changeGlobalVariables("allshifts_client", newValue);
  }
  const selectEmployee = (event, newValue) => {
    setEmployee(newValue);
    changeGlobalVariables("allshifts_employee", newValue);
  }
  const selectWorkOrder = (event, newValue) => {
    setWorkOrder(newValue);
    changeGlobalVariables("allshifts_workOrder", newValue);
  }
  const changeApproved = (e) => {
    let newValue = e.target.value;
    setapproved(newValue);
    changeGlobalVariables("allshifts_approved", newValue);
  }
  const changeWorked = (e) => {
    let newValue = e.target.value;
    setWorked(newValue);
    changeGlobalVariables("allshifts_worked", newValue);
  }
  const changeStartDate = (e) => {
    let newValue = e.target.value;
    setStartDate(newValue);
    changeGlobalVariables("allshifts_startDate", newValue);
  }
  const changeEndDate = (e) => {
    let newValue = e.target.value;
    setEndDate(newValue);
    changeGlobalVariables("allshifts_endDate", newValue);
  }

  const searchDailyShifts = () => {
    let query = `/server/request/getdailyshifts?page=${page}&rows=${rowsPerPage}&approved=${approved}&startdate=${startDate}&enddate=${endDate}&worked=${worked}`;
    if (employee && employee.code) query += `&employee=${employee.code}`;
    if (client && client.code) query += `&client=${client.code}`;
    if (workOrder && workOrder.work_report) query += `&wo=${workOrder.work_report}`;
    if (manager && manager.id) query += `&manager=${manager.id}`;
    get(query)
    .then((response) => {
      if (response && response.results && response.results.length > 0) {
        if (response.total) setTotalRows(response.total);
        let allResults = response.results;
        if (approved != "no") {
          setHaveData(true);
          setHaveMissingWO(false);
          setAllShifts(allResults);
          let diffRows = allResults.filter((e,i) => {
            if (i+1 < allResults.length && allResults[i+1].employee === e.employee && allResults[i+1].date === e.date)  return false;
            else return true;
          });
          setAllDiffShifts(diffRows);
          setMissingWO();
        }
        else {
          setHaveMissingWO(true)
          setHaveData(false)
          setAllShifts()
          setMissingWO(allResults);
          setholidayActions(allResults.map((e)=>false))
          setleaveActions(allResults.map((e)=>"0"))
        }
      }
      else {
        setAllShifts()
        setHaveMissingWO(false)
        setHaveData(false)
        setMissingWO()
      }
    })
  }
  const selectManager = (event, newValue) => {
    setManager(newValue);
    changeGlobalVariables("allshifts_manager", newValue);
  }

  useEffect(()=>{
    get(`/server/request/getclients`)
    .then((response) => {
      if (response && response.clients && response.clients.length > 0) {
        setClients(response.clients)
      }
    })
    get('/server/request/getemployees')
    .then((response) => {
      if (response && response.employees && response.employees.length > 0) {
        setEmployees(response.employees);
      }
    });
    get('/server/request/getleavetypesmodel')
    .then((response) => {
      if (response && response.leavemodel) {
        setLeaveModel(response.leavemodel);
      }
      if (response && response.workhoursmodel) {
        setWorkHoursModel(response.workhoursmodel);
      }
    });
    get(`/server/request/getworkorders`)
    .then((response) => {
      if (response && response.wo && response.wo.length > 0) {
        setWorkOrders(response.wo)
      }
    });
  },[]);

  useEffect(()=>{
    searchDailyShifts()
  },[employee,client,manager,workOrder,startDate,endDate,approved,page,rowsPerPage,worked]);

  const downloadFileMissing= () => {
    let options = {
       openAsDownload: true,
       format: 'xlsx',
       filename: 'partesdetrabajonocreados'
    };
    let allData = missingWO.map((row)=>{
      return [
       `${getVal(row.id)}`,
       `${getVal(row.name)}`
       ]
     });
    let filters = [];
    if (client && client.code) filters.push(["Cliente",client.code]);
    if (startDate) filters.push(["Fecha",startDate]);
    filters.push([]);
    let sheets =   [
         {
             name: 'Partes de Trabajo No Creados',
             from: {
                 table: String/Element,
                 array: [
                   ...filters,
                   ["Identificador","Nombre del empleado"],
                   ...allData
                 ]
             }
         }
     ]
    ExcellentExport.convert(options, sheets);
  }

    const downloadFile = () => {
      let options = {
         openAsDownload: true,
         format: 'xlsx',
         filename: 'partesdetrabajo'
      };
      let allData = allShifts.map((row)=>{
        return [
         `${displayDate(row.date)}`,
         `${getVal(row.client)}`,
         `${getVal(row.working_order)}`,
         `${getVal(row.employee)}`,
         `${getVal(row.wo_hours)}`,
         `${getStatus(row.ds_status)}`,
         `${getStatus(row.dsm_status)}`,
         `${getBoolean(row.holidays)}`,
         `${getLeaveType(row.paidleave)}`
         ]
       });
      let filters = [];
      if (client && client.code) filters.push(["Cliente",client.code]);
      if (employee && employee.code) filters.push(["Empleado",employee.code]);
      if (workOrder && workOrder.work_report) filters.push(["Orden de trabajo",workOrder.work_report]);
      if (manager && manager.id) filters.push(["Encargado",manager.id]);
      if (startDate) filters.push(["Fecha de inicio",startDate]);
      if (endDate) filters.push(["Fecha de fin",endDate]);
      if (approved) filters.push(["Estado de los partes",getWOstate(approved)]);
      filters.push([]);
      let sheets =   [
           {
               name: 'Partes de Trabajo',
               from: {
                   table: String/Element,
                   array: [
                     ...filters,
                     ["Fecha","Cliente","Orden de Trabajo","Empleado","Horas trabajadas","Estado Parte","Estado Orden","Vacaciones","Permiso retribuido","Orden abierta"],
                     ...allData
                   ]
               }
           }
       ]
      ExcellentExport.convert(options, sheets);
    }

    const downloadFileHours = () => {
      let options = {
         openAsDownload: true,
         format: 'xlsx',
         filename: 'horas_trabajadas'
      };
      let titlesLength = 1;
      let query = `/server/request/getdailyshifts?approved=${approved}&startdate=${startDate}&enddate=${endDate}&worked=${worked}`;
      if (employee && employee.code) query += `&employee=${employee.code}`;
      if (client && client.code) query += `&client=${client.code}`;
      if (workOrder && workOrder.work_report) query += `&wo=${workOrder.work_report}`;
      if (manager && manager.id) query += `&manager=${manager.id}`;
      let isTiming = false;
      get(query)
      .then((response) => {//console.log(response.results)
        let allData = response.results.map((row)=>{
          let data2send = [
           `${displayDate(row.date)}`,
           `${getVal(row.employee)}`,
           `${getVal(row.name_employee)}`
         ];
          if (workHoursModel?.working_hours?.system !== "auto" && (row.time_started || row.time_finished)) {
            data2send.push( `${getVal(row.time_started)}`, `${getVal(row.time_finished)}` );
            }
          else if (row.timings) {
            isTiming = true;
            let newTimings = JSON.parse(row.timings);
            if (newTimings?.times?.length > 0) {
              newTimings.times.forEach((e,i)=>{
                let trackingS = (e.starttrack) ? `=HYPERLINK("https://www.google.com/maps/search/?api=1&query=${e.starttrack.lat}%2C${e.starttrack.long}","${e.starttrack.lat},${e.starttrack.long}")` : (e.manualstart) ? "manual" : "";
                let trackingE = (e.endtrack) ? `=HYPERLINK("https://www.google.com/maps/search/?api=1&query=${e.endtrack.lat}%2C${e.endtrack.long}","${e.endtrack.lat}%2C${e.endtrack.long}")`: (e.manualend) ? "manual" : "";
                data2send.push( `${getTime(e.start)}`, `${getTime(e.end)}`, trackingS, trackingE);
              });
              if (newTimings.times.length > titlesLength) titlesLength = newTimings.times.length;
            }
          }
          return data2send;
         });
        let filters = [];
        if (client && client.code) filters.push(["Cliente",client.code]);
        if (employee && employee.code) filters.push(["Empleado",employee.code]);
        if (workOrder && workOrder.work_report) filters.push(["Orden de trabajo",workOrder.work_report]);
        if (manager && manager.id) filters.push(["Encargado",manager.id]);
        if (startDate) filters.push(["Fecha de inicio",startDate]);
        if (endDate) filters.push(["Fecha de fin",endDate]);
        if (approved) filters.push(["Estado de los partes",getWOstate(approved)]);
        filters.push([]);
        let titles = ["Fecha","Empleado","Nombre empleado"];
        for (let j=0;j<titlesLength;j++) {
          titles.push("inicio","fin");
          if (isTiming) titles.push("inicio geoloc","fin geoloc");
        }
        let sheets =   [
             {
                 name: 'Horas trabajadas',
                 from: {
                     table: String/Element,
                     array: [
                       ...filters,
                       titles,
                       ...allData
                     ]
                 }
             }
         ]
        ExcellentExport.convert(options, sheets);
      })
    }

  const downloadFileNew = () => {
    let options = {
       openAsDownload: true,
       format: 'xlsx',
       filename: 'partesdetrabajo'
    };
    let query = `/server/request/getfulldailyshiftsdetails?approved=${approved}&startdate=${startDate}&enddate=${endDate}&worked=${worked}`;
    if (employee && employee.code) query += `&employee=${employee.code}`;
    if (client && client.code) query += `&client=${client.code}`;
    if (workOrder && workOrder.work_report) query += `&wo=${workOrder.work_report}`;
    if (manager && manager.id) query += `&manager=${manager.id}`;
    get(query)
    .then((response) => {//console.log(response.results)
      let allData = response.results.map((row)=>{//console.log(row)
        return [
         `${displayDate(row.date)}`,
         `${getVal(row.employee)}`,
         `${getVal(row.name_employee)}`,
         `${getVal(row.working_order)}`,
         `${getVal(row.manager)}`,
         `${getVal(row.name_manager)}`,
         `${getVal(row.client)}`,
         `${getVal(row.name_client)}`,
         `${getBoolean(row.holidays)}`,
         `${getLeaveType(row.paidleave)}`,
         `${getSingleWOstate(row.status)}`,
         `${getSingleWOstate(row.dsm_status)}`,
         ...getValAction(row.wo_actions)
         ]
       });
      let filters = [];
      if (client && client.code) filters.push(["Cliente",client.code]);
      if (employee && employee.code) filters.push(["Empleado",employee.code]);
      if (workOrder && workOrder.work_report) filters.push(["Orden de trabajo",workOrder.work_report]);
      if (manager && manager.id) filters.push(["Encargado",manager.id]);
      if (startDate) filters.push(["Fecha de inicio",startDate]);
      if (endDate) filters.push(["Fecha de fin",endDate]);
      if (approved) filters.push(["Estado de los partes",getWOstate(approved)]);
      filters.push([]);
      let titles = ["Fecha","Empleado","Nombre empleado","Orden de Trabajo","Encargado","Nombre encargado","Cliente","Nombre cliente","Vacaciones","Permiso retribuido","Estado Parte","Estado Orden","Descripción"];
      if (workHoursModel.elements) {
        for (var i=0; i<workHoursModel.elements.length; i++) {
          titles.push(workHoursModel.elements[i].id)
          if (workHoursModel.elements[i].def === "dailyhours" && workHoursModel.elements[i].multipleoptions) {
            for (var j=0; j<workHoursModel.elements[i].multipleoptions.length; j++) titles.push(workHoursModel.elements[i].multipleoptions[j]);
          }
        }
      }
      let sheets =   [
           {
               name: 'Partes de Trabajo',
               from: {
                   table: String/Element,
                   array: [
                     ...filters,
                     titles,
                     ...allData
                   ]
               }
           }
       ]
      ExcellentExport.convert(options, sheets);
    })
  }
  const isHolidays = (type,row,index,e) => {
    let newHoliday = (type === "holiday") ? !holidayActions[index] : holidayActions[index];
    let newLeave = (type === "leave" && e && e.target && e.target.value) ? e.target.value : leaveActions[index];
    if (type === "holiday" && newHoliday) newLeave = "0";
    else if (type === "leave" && newLeave !== "0") newHoliday = false;
    post(`/server/request/isaholiday`,{id:row.id,date:startDate,holiday:newHoliday,leave:newLeave})
    .then((response) => {
      if (response && response.success) {
        let newholidayActions = [...holidayActions]
        let newLeaveActions = [...leaveActions]
        newholidayActions[index] = newHoliday
        newLeaveActions[index] = newLeave
        setholidayActions(newholidayActions)
        setleaveActions(newLeaveActions)
      }
    })
  }
  const sendNotification = (row) => {
    get(`/server/request/notifypending?id=${row.id}&date=${startDate}`)
    .then((response) => {
      if (response && response.success) {
        setisNotified("Notificación enviada")
      }
      else if (response.error) searchDailyShifts();
    })
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

  };
  const handleChangeRowsPerPage = (event) => {
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
  };
  return (
    <Container maxWidth="md" className={classes.contentTop}>
      <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            Revisión de partes de trabajo
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.center}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Estado</InputLabel>
            <Select
              label="Estado"
              value={approved}
              onChange={changeApproved}
            >
              <ListSubheader><strong>No creados</strong></ListSubheader>
              <MenuItem value={"no"} className={classes.indented}>Sin parte</MenuItem>
              <ListSubheader><strong>Creados</strong></ListSubheader>
              <MenuItem value={0} className={classes.indented}>Pendientes de firmar</MenuItem>
              <MenuItem value={1} className={classes.indented}>Pendientes de revisión</MenuItem>
              <MenuItem value={2} className={classes.indented}>Rechazados</MenuItem>
              <MenuItem value={3} className={classes.indented}>Aprobados</MenuItem>
              <MenuItem value={4} className={classes.indented}>Todos los partes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {approved !== "no" &&
          <>
            <Grid item xs={6} className={classes.center}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Actividad laboral</InputLabel>
                <Select
                  label="Actividad laboral"
                  value={worked}
                  onChange={changeWorked}
                >
                  <MenuItem value={1} className={classes.indented}>Trabajados</MenuItem>
                  <MenuItem value={2} className={classes.indented}>Retribuidos no trabajados</MenuItem>
                  <MenuItem value={3} className={classes.indented}>Todos</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          {employees &&
            <Grid item xs={6} id="search">
              <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-employees"
                value={employee}
                onChange={selectEmployee}
                options={employees}
                getOptionLabel={(option) => `${option.name} (${option.code})`}
                renderInput={(params) => <TextField {...params} label="Empleado" placeholder="comienza a teclear el nombre..."/>}
              />
            </Grid>
          }
          <Grid item xs={6} id="search">
            <Autocomplete
              disablePortal
              fullWidth
              id="combo-box-clients"
              value={client}
              onChange={selectClient}
              options={clients}
              getOptionLabel={(option) => `${option.code} ${option.name}`}
              renderInput={(params) => <TextField {...params} label="Cliente" placeholder="comienza a teclear el nombre2..."/>}
            />
          </Grid>
          <Grid item xs={6} id="search">
            <Autocomplete
              disablePortal
              fullWidth
              id="combo-box-wo"
              value={workOrder}
              onChange={selectWorkOrder}
              options={workOrders}
              getOptionLabel={(option) => `${option.work_report} ${option.description}`}
              renderInput={(params) => <TextField {...params} label="Orden de trabajo" placeholder="comienza a teclear el nombre..."/>}
            />
          </Grid>
          {managers &&
            <Grid item xs={6} id="search">
              <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-manager"
                value={manager}
                onChange={selectManager}
                options={managers}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Encargados" />}
              />
            </Grid>
          }
          </>
        }
        <Grid item xs={isMobileSize ? 6 : 3} id="search-start-date">
          <TextField id="outlined-start-date" label={approved !== "no" ? "Fecha inicio" : "Fecha"} variant="outlined" type="date" defaultValue={startDate} onChange={changeStartDate}/>
        </Grid>
        {approved !== "no" &&
          <Grid item xs={isMobileSize ? 6 : 3} id="search-end-date">
            <TextField id="outlined-end-date" label="Fecha fin" variant="outlined" type="date" defaultValue={endDate} onChange={changeEndDate}/>
          </Grid>
        }
        <Grid item xs={12} id="search" align="center">
          {!haveData && !haveMissingWO &&
              <Typography variant="body" color="textSecondary">
                Sin datos para esta búsqueda
              </Typography>
          }
        </Grid>
        <Grid item xs={12} id="search" align="center">
        {missingWO &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell align="center">vacaciones</TableCell>
                  <TableCell align="center">permiso remunerado</TableCell>
                  <TableCell align="center">solicitar cumplimentar</TableCell>
                  <TableCell align="right">Identificador</TableCell>
                  <TableCell align="right">Crear</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {missingWO.map((row, index)=>
                  <TableRow key={"missingwo"+index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        color="primary"
                        checked={holidayActions[index]}
                        onChange={()=>isHolidays("holiday",row,index)}
                        key={index+"holidayscheck"} />
                    </TableCell>
                    <TableCell align="center">
                      <FormControl fullWidth>
                        <Select
                          label="Permiso Retribuido"
                          value={leaveActions[index]}
                          onChange={(e)=>isHolidays("leave",row,index,e)}
                        >
                          <MenuItem value="0" default>-</MenuItem>
                          {leaveModel && leaveModel.types && leaveModel.types.map((e,index1)=>
                            <MenuItem key={"nameItem"+index1} value={e.def}>{e.name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      {row.push_notif === 1 &&
                        <NotificationsActiveIcon onClick={()=>sendNotification(row,index)}/>
                      }
                    </TableCell>
                    <TableCell align="right">{row.id}</TableCell>
                    <TableCell align="right"><EditIcon onClick={()=>go2date(startDate,row.id,Boolean(!holidayActions[index] && (!leaveActions[index] || leaveActions[index] === "0")),row.name)} className={classes.strongpointer}/></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {allShifts &&
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    {!client &&
                      <TableCell>Cliente</TableCell>
                    }
                    {!workOrder &&
                      <TableCell>Orden de Trabajo</TableCell>
                    }
                    {!employee &&
                      <TableCell>Empleado</TableCell>
                    }
                    <TableCell align="center">Horas</TableCell>
                    <TableCell align="center">Picaje</TableCell>
                    <TableCell align="center">Estado</TableCell>
                    <TableCell align="center">Editar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allShifts.map((row,index) => (
                    <Row
                      index={index}
                      key={index}
                      row={row}
                      managers={managers}
                      projects={projects}
                      employees={employees}
                      employee={employee}
                      client={client}
                      workOrder={workOrder}
                      manager={manager}
                      go2date={go2date}
                      leaveModel={leaveModel}
                      allShifts={allShifts}
                      allDiffShifts={allDiffShifts}
                      />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            }
            {(totalRows > rowsPerPage) &&
              <TablePagination
                rowsPerPageOptions={[15, 25, 50]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={<span>Filas:</span>}
                labelDisplayedRows={({ page }) => `Página ${page+1} de ${Math.ceil(totalRows/rowsPerPage)}`}
              />
            }
          </Grid>
            {haveData && 1===0 &&
              <Grid item xs={12} id="search" align="center">
                <Button key="send" variant="contained" onClick={downloadFile}>Descargar en Excel</Button>
              </Grid>
            }
            {haveData &&
              <>
                <Grid item xs={6} id="search" align="center">
                  <Button key="send" variant="contained" onClick={downloadFileNew}>Descargar en Excel</Button>
                </Grid>
                <Grid item xs={6} id="search" align="center">
                  <Button key="send" variant="contained" onClick={downloadFileHours}>Descargar Horas entrada/salida</Button>
                </Grid>
              </>
            }
            {haveMissingWO &&
            <Grid item xs={12} id="search" align="center">
              <Button key="send" variant="contained" onClick={downloadFileMissing}>Descargar en Excel</Button>
            </Grid>
            }
        </Grid>
        <Snackbar open={Boolean(isNotified)} autoHideDuration={6000} onClose={()=>setisNotified()}>
          <MuiAlert elevation={6} variant="filled"  onClose={()=>setisNotified()} severity="success" sx={{ width: '100%' }}>
            {isNotified}
          </MuiAlert>
        </Snackbar>
    </Container>
  );

}
