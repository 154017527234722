import React, { useEffect } from 'react';

import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PeopleIcon from '@mui/icons-material/People';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useStyles } from "./styles";
import {get, post} from "./commons";

export default function Analytics({biz,shiftsigned,managers,projects}) {
  const classes = useStyles();
  const [employees, setEmployees] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [workOrders, setWorkOrders] = React.useState([]);
  const [employee, setEmployee] = React.useState();
  const [shiftDate, setShiftDate] = React.useState();
  const [analyticalData, setAnalyticalData] = React.useState();

  useEffect(()=>{
    get(`/server/request/getclients`)
    .then((response) => {
      if (response && response.clients && response.clients.length > 0) {
        setClients(response.clients)
      }
    })
    get('/server/request/getemployees')
    .then((response) => {
      if (response && response.employees && response.employees.length > 0) {
        setEmployees(response.employees);
      }
    });
    get(`/server/request/getworkorders`)
    .then((response) => {
      if (response && response.wo && response.wo.length > 0) {
        setWorkOrders(response.wo)
      }
    });
  },[]);

  const getData = () => {console.log(employee)
    if (employee?.code && shiftDate) {
      get(`/server/request/getanalyticaldata?employee=${employee.code}&date=${shiftDate}`)
      .then((response) => {
        if (response && response.data) {
          setAnalyticalData(response.data)
        }
      });
    }
  }
  const isNotJson = (item) => {
    let value = typeof item !== "string" ? JSON.stringify(item) : item;
    try {
      value = JSON.parse(value);
    } catch (e) {
      return true;
    }
    return !(typeof value === "object" && value !== null);
  }
  const retriveAnalyticalData = () => {
    let allData = [];
    if (analyticalData) {
      if (analyticalData[0] && analyticalData[0][0]) {
        let all0keys = Object.keys(analyticalData[0][0]);
        if (all0keys.length > 0) allData.push(<Grid item xs={12} id={`Parte Diario`}><strong>Parte Diario</strong></Grid>);
        all0keys.forEach((e,i)=>{
          let newData = isNotJson(analyticalData[0][0][e]) ? analyticalData[0][0][e] : <textarea rows="6" cols="90">{JSON.stringify(JSON.parse(analyticalData[0][0][e]),null,'\t')}</textarea>;
          if (analyticalData[0][0][e] !== null) allData.push ( <>
            <Grid item xs={2} id={`all0keys-${i}-a`}> {e} </Grid>
            <Grid item xs={10} id={`all0keys-${i}-b`}> {newData} </Grid>
            </> )
        });
      }
      if (analyticalData[1]) {
        analyticalData[1].forEach((f,j)=>{
          let all1keys = Object.keys(f);
          if (f.manager) {
            let manag = employees.filter(k=>k.code === f.manager)
            if (manag[0]?.name) allData.push(<Grid item xs={12} id={`Parte Diario 2 ${j}`}>APROBACION ENCARGADO: <strong>{manag[0].name}</strong></Grid>);
          }
          all1keys.forEach((e,i)=>{
            if (f[e] !== null) allData.push ( <>
              <Grid item xs={2} id={`all0keys-${i}-a`}> {e} </Grid>
              <Grid item xs={10} id={`all0keys-${i}-b`}> {f[e]} </Grid>
              </> )
          });
          return allData;
        });
      }
      if (analyticalData[2]) {
        analyticalData[2].forEach((f,j)=>{
          allData.push(<Grid item xs={12} id={`Parte Diario 3`}><strong>REGISTRO {j}</strong></Grid>);
          let all2keys = Object.keys(f);
          all2keys.forEach((e,i)=>{
            let newData = isNotJson(f[e]) ? f[e]: <textarea rows="6" cols="90">{JSON.stringify(JSON.parse(f[e]),null,'\t')}</textarea>;
            if (f[e] !== null) allData.push ( <>
              <Grid item xs={2} id={`all0keys-${i}-a`}> {e} </Grid>
              <Grid item xs={10} id={`all0keys-${i}-b`}> {newData} </Grid>
              </> )
          });
          return allData;
        });
      }
      return allData;
    }
  }
  return (
    <Container maxWidth="md" className={classes.contentTop}>
      <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            Analytics
          </Typography>
        </Grid>
      {employees &&
        <Grid item xs={6} id="search">
          <Autocomplete
            disablePortal
            fullWidth
            id="combo-box-employees"
            value={employee}
            onChange={(e, newValue) => setEmployee(newValue)}
            options={employees}
            getOptionLabel={(option) => `${option.name} (${option.code})`}
            renderInput={(params) => <TextField {...params} label="Empleado del parte" placeholder="comienza a teclear el nombre..."/>}
          />
        </Grid>
      }
      <Grid item xs={6} id="search-end-date">
        <TextField id="outlined-date" label="Fecha parte" variant="outlined" type="date" defaultValue={shiftDate} onChange={(e) => setShiftDate(e.target.value)}/>
      </Grid>
      <Grid item xs={12} id="search" align="center">
        <Button key="send" variant="contained" onClick={getData}>buscar</Button>
      </Grid>
      {analyticalData && retriveAnalyticalData()}
    </Grid>
  </Container>
  );

}
