import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

//import stringSimilarity from "string-similarity";

import { useStyles } from "./styles";
import {get, post} from "./commons";

export default function WorkReport({biz,shiftsigned,managers,projects,retrieveBizData}) {
  const classes = useStyles();
  const [totalRows, setTotalRows] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [woData, setwoData] = React.useState([]);
  const [woDataFiltered, setwoDataFiltered] = React.useState([]);
  const [displaywoData, setDisplaywoData] = React.useState([]);
  const [order, setOrder] = React.useState({});

  const [model, setModel] = React.useState();
  const [isSaved, setisSaved] = React.useState(false);
  const [employees, setEmployees] = React.useState();
  const [openDialogManager, setOpenDialogManager] = React.useState(false);
  const [fullData2Store, setfullData2Store] = React.useState();
  const [awaitFile, setAwaitFile] = React.useState(false);

  const sorting = (items,field) => {
    let newOrder = {...order}
    newOrder[field] = (newOrder[field]) ? !newOrder[field] : true;
    setOrder(newOrder);
    return items.sort(function(a, b) {
      let alower = (a[field]) ? a[field].toLowerCase() : null;
      let blower = (b[field]) ? b[field].toLowerCase() : null;
      if (alower && blower) {
        if (newOrder[field]) {
          if (alower > blower) return 1;
          if (alower < blower) return -1;
        }
        else {
            if (alower < blower) return 1;
            if (alower > blower) return -1;
          }
      }
      return 0;
    });
  }

  const retriveDataDB = () => {
    get(`/server/request/getworkordersfullset`).then((response)=>{
      let modelJSON, modelreferences;
      if (response.model && response.model[0] && response.model[0].model) {
        modelJSON = JSON.parse(response.model[0].model);
        modelreferences = modelJSON.references.reduce((a,c)=>{
          if (c.values) {
            let allkeys = Object.keys(c.values);
            let vals = allkeys.reduce((aa,cc)=>{
              aa[c.values[cc]] = cc;
              return aa;
            },{});//console.log(vals)
            a[c.db] = {id:c.id,vals:vals};
          }
          else a[c.db] = {id:c.id};
          return a;
        },{})
        if (modelJSON) {
          setModel(modelJSON);
        }
      }
      if (response.workorders) {
        let allRows = (modelreferences && modelreferences.status && modelreferences.status.vals) ? sorting(response.workorders,"name").map((e)=>{
          return {...e,status:modelreferences.status.vals[e.status]}
        }) : sorting(response.workorders,"name");
        let allRowsFiltered = (response.clients) ? allRows.map((e)=>{
          let client = response.clients.filter((f)=>f.code === e.client)
          if (client && client[0] && client[0].name) return {...e,client:client[0].name}
          else return e
        }) : allRows;

        setwoData(allRowsFiltered);//console.log(allRows)
        setwoDataFiltered(allRowsFiltered);
        setDisplaywoData(allRowsFiltered.slice(0,rowsPerPage));
        setTotalRows(response.workorders.length);
      }
      if (response.employees) setEmployees(response.employees)
    });
  }

  useEffect(()=>{
    retriveDataDB()
  },[]);

  const reorder = (field) => {
    let allRows = sorting(woDataFiltered,field);
    setwoDataFiltered(allRows);
    setPage(0);
    setDisplaywoData(allRows.slice(0,rowsPerPage));
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    let start = newPage*rowsPerPage;
    setDisplaywoData(woDataFiltered.slice(start,newPage*rowsPerPage+rowsPerPage));
  };
  const handleChangeRowsPerPage = (event) => {
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
    setDisplaywoData(woDataFiltered.slice(0,rows));
  };
  const filterData = (text) => {
    let filteredwoData = (text) ? woData.filter((e)=>{
      if (e.description && e.description.toLowerCase().indexOf(text.toLowerCase())>-1) return true;
      else if (e.work_report && e.work_report.indexOf(text)>-1) return true;
      else if (e.client && e.client.toLowerCase().indexOf(text.toLowerCase())>-1) return true;
      else return false;
    }) : woData;
    setTotalRows(filteredwoData.length);
    setPage(0);
    setwoDataFiltered(filteredwoData);
    setDisplaywoData(filteredwoData.slice(0,rowsPerPage));
  };
  const download_txt = () => {//console.log(model)
    if (fullData2Store && fullData2Store.pending) {
      let header = ["client","work_report","description","workshop_manager","assembly_manager"].map((e)=>{
        let name = model.references.filter((f)=>f.db === e);
        if (name && name[0] && name[0].id) return name[0].id;
        else return e;
      }).toString();
      let text2send = fullData2Store.pending.reduce((a,c)=>{
        let workshop = (c.workshop_manager_label) ? c.workshop_manager_label : "";
        let assembly = (c.assembly_manager_label) ? c.assembly_manager_label : "";
        let newline = `${c.client};${c.work_report};${c.description};${workshop};${assembly}\n`;
        a+=newline;
        return a;
      },`${header}\n`);
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:attachment/text,' + encodeURI(text2send);
      hiddenElement.target = '_blank';
      hiddenElement.download = `errors.csv`
      hiddenElement.click();
    }
  }
  const selectFile = (event) => {
    var reader = new FileReader();
    reader.onerror = (evt) => {
        console.log("err",evt.target.error.name);
    };
    reader.onload = function(){
      setAwaitFile(false);
      event.target.value = null;
      let text = reader.result.replace(/\r/gi,"");
      if (model.divider) {
        let rows = text.split("\n");
        let headers = [];
        let multichoices = {};
        let relations = {};
        let ambiguity_vals = 0;
        let newData = rows.map((e,i)=>{
          if (!model.header) {
            headers = model.references.map((header)=>{
              if (header.db) {
                if (header.type && header.type === "multiplechoice" && header.values) multichoices[header.db] = header.values;
                if (header.relation) relations[header.db] = header.relation;
                return header.db;
                }
              else return null;
            })
          }
          if (model.header && i===0) {
            headers = e.split(model.divider).map((header_e)=>{
              let modelValues = model.references.filter((f)=>f.id===header_e);
              if (modelValues[0] && modelValues[0].db) {
                if (modelValues[0].type && modelValues[0].type === "multiplechoice" && modelValues[0].values) multichoices[modelValues[0].db] = modelValues[0].values;
                if (modelValues[0].relation) relations[modelValues[0].db] = modelValues[0].relation;
                return modelValues[0].db;
                }
              else return null;
            })
          }
          else {
            let rowData = e.split(model.divider);
            if (rowData.length === headers.length) {
              let newVal = {}
              headers.forEach((e,i)=>{
                if (e) {
                  if (multichoices[e]) newVal[e] = multichoices[e][rowData[i]];
                  else newVal[e] = rowData[i];
                }
              })
              return newVal
            }
          }
        })
        .filter(n=>n).map((e)=>{
          if (Object.keys(relations).length > 0) {
            if (relations.assembly_manager === "employee.name" && relations.workshop_manager === "employee.name") {
              if (e.workshop_manager || e.assembly_manager) {
                let wmNormalized = (e.workshop_manager) ? e.workshop_manager.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : null;
                let amNormalized = (e.assembly_manager) ? e.assembly_manager.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : null;

                let employeeIDwmFull = employees.filter((n)=>{
                  if (n.name && e.workshop_manager) return n.name.toLowerCase() === e.workshop_manager.toLowerCase();
                  else return false;
                });
                let employeeIDamFull = employees.filter((n)=>{
                  if (n.name && e.assembly_manager) return n.name.toLowerCase() === e.assembly_manager.toLowerCase();
                  else return false;
                });
                let employeeIDwm = employees.filter((n)=>{
                  let normlizedName = (n.name) ? n.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : null;
                  if (normlizedName && wmNormalized) return normlizedName === wmNormalized;
                  else return false;
                });
                 let employeeIDam = employees.filter((n)=>{
                  let normlizedName = (n.name) ? n.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : null;
                  if (normlizedName && amNormalized) return normlizedName === amNormalized;
                  else return false;
                });
                let employeeIDSubwm = employees.filter((n)=>{
                  let normlizedName = (n.name) ? n.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : null;
                  if (normlizedName && wmNormalized) return wmNormalized.indexOf(normlizedName.substr(0,wmNormalized.length-2).toLowerCase()) > -1
                  else return false;
                });
                let employeeIDSubam = employees.filter((n)=>{
                  let normlizedName = (n.name) ? n.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : null;
                  if (normlizedName && amNormalized) return amNormalized.indexOf(normlizedName.substr(0,amNormalized.length-2).toLowerCase()) > -1
                  else return false;
                });

                let new_e = {...e}

                if (e.workshop_manager) {
                  if (employeeIDwmFull.length === 1)  new_e.workshop_manager = employeeIDwmFull[0].code;
                  else if (employeeIDwm.length === 1)  new_e.workshop_manager = employeeIDwm[0].code;
                  else if (employeeIDSubwm.length === 1)  new_e.workshop_manager = employeeIDSubwm[0].code;
                  else {
                    ambiguity_vals++
                    new_e.workshop_manager_label = new_e.workshop_manager;
                    new_e.workshop_manager = "";
                    new_e.workshop_manager_pending = "unknown";
                  }
                }
                if (e.assembly_manager) {
                  if (employeeIDamFull.length === 1)  new_e.assembly_manager = employeeIDamFull[0].code;
                  else if (employeeIDam.length === 1) new_e.assembly_manager = employeeIDam[0].code;
                  else if (employeeIDSubam.length === 1)  new_e.assembly_manager = employeeIDSubam[0].code;
                  else {
                    ambiguity_vals++
                    new_e.assembly_manager_label = new_e.assembly_manager;
                    new_e.assembly_manager = "";
                    new_e.assembly_manager_pending = "unknown";
                  }
                }
                return new_e;

              }
              else return e;
            }
            else return false;
          }
          else return e;
        });
        if (Object.keys(relations).length > 0 && ambiguity_vals > 0) {
          let pending4manager = newData.filter((e)=>e.workshop_manager_pending||e.assembly_manager_pending);
          if (pending4manager) {
            setfullData2Store({pending:pending4manager,fullset:newData});
            setOpenDialogManager(true);
          }
        }
        else sendata2Store(newData);
      }
    };
    reader.readAsText(event.target.files[0],'ISO-8859-1');
  }
  const sendata2Store = (data2store) => {
    post(`/server/request/setworkorders`,{data2store}).then((response)=>{
      if (response.updated) {
        retriveDataDB();
        setisSaved(true);
        setOpenDialogManager(false);
        setfullData2Store();
        retrieveBizData();
      }
    });
  }
  const disambiguateManager = (event, manager, workorder, type) => {
    if (manager.id && workorder.work_report) {
      let newfullData2StorePending = fullData2Store.pending.map((e)=>{
        if (e.work_report === workorder.work_report) {
          let newsorted = (e.sorted) ? [...e.sorted,type] : [type];
          return {...e, sorted:newsorted}
        }
        else return e
      });
      let newfullData2StoreFullset = fullData2Store.fullset.map((e, index)=>{
        if (e.work_report === workorder.work_report) {
          let new_e = {...e}
          new_e[type]=manager.id;
          return new_e;
        }
        else return e;
      });
      setfullData2Store({pending:newfullData2StorePending,fullset:newfullData2StoreFullset});
    }
  }
  const sendFullDataSet = () => {
    sendata2Store(fullData2Store.fullset);
    setOpenDialogManager(false);
  }
  return (
      <Container maxWidth="md" className={classes.contentTop}>
      <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            Órdenes de trabajo
          </Typography>
        </Grid>
        <Grid item xs={6} id="search">
          <FormControl variant="standard">
            <InputLabel htmlFor="search">
              Buscar
            </InputLabel>
            <Input
              id="search"
              autoFocus
              onChange={(e)=>filterData(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        {model && model.divider &&
          <Grid item xs={6} id="load" className={classes.end}>
            <input
              accept='text/txt'
              className={classes.nodisplay}
              id="uploaDOC"
              multiple
              type="file"
              name="csvworkorders"
              onClick={()=>setAwaitFile(true)}
              onChange={(e)=>{selectFile(e)}}
            />
            <label htmlFor="uploaDOC" >
              <Button
                variant="outlined"
                startIcon={awaitFile ? <CircularProgress size={18}/> : <UploadIcon/>}
                size="small"
                component="span"
                className={classes.button1}
              > Subir fichero</Button>
            </label>
          </Grid>
          }
      </Grid>
      {displaywoData.length > 0 &&
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell key="code" className={classes.strongpointer} onClick={()=>reorder("work_report")}>Código</TableCell>
                  <TableCell key="desc" className={classes.strongpointer} onClick={()=>reorder("description")}>Descripción</TableCell>
                  <TableCell key="client" className={classes.strongpointer} onClick={()=>reorder("client")}>Cliente</TableCell>
                  <TableCell key="status" className={classes.strongpointer} onClick={()=>reorder("status")}>Situación</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displaywoData.map((e,i) => (
                  <>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                      <>
                        <TableCell key={"code"+i}>{e.work_report}</TableCell>
                        <TableCell key={"desc"+i}>{e.description}</TableCell>
                        <TableCell key={"client"+i}>{e.client}</TableCell>
                        <TableCell key={"status"+i}>{e.status}</TableCell>
                      </>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Filas:</span>}
            labelDisplayedRows={({ page }) => `Página ${page+1} de ${Math.ceil(totalRows/rowsPerPage)}`}
          />
        </>
        }
        <Snackbar open={isSaved} autoHideDuration={6000} onClose={()=>setisSaved(false)}>
          <MuiAlert elevation={6} variant="filled"  onClose={()=>setisSaved(false)} severity="success" sx={{ width: '100%' }}>
            Guardado!
          </MuiAlert>
        </Snackbar>
        <Dialog
          fullScreen
          open={openDialogManager}
          keepMounted
          onClose={()=>setOpenDialogManager(false)}
        >
          <DialogTitle>Identificar Encargados</DialogTitle>
            <DialogContent>
              <Grid container  justify="space-evenly" alignItems="center" spacing={1}>
                <Grid item md={1} id="search">Código</Grid>
                <Grid item md={3} id="search">Descripción</Grid>
                <Grid item md={4} id="search">Responsable montaje</Grid>
                <Grid item md={4} id="search">Responsable taller</Grid>
                {fullData2Store && fullData2Store.pending && fullData2Store.pending.map((e)=>
                  <>
                    <Grid item md={1} id="search">{e.work_report}</Grid>
                    <Grid item md={3} id="search">{e.description}</Grid>
                    <Grid item md={4} id="search">
                      {e.assembly_manager_pending &&
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={employees.map((d)=>{
                            if (d.name && d.code) return {label:d.name,id:d.code}
                            else return false
                          })}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label={e.assembly_manager_label} id={e.code} />}
                          onChange={(event, newValue) => {
                            disambiguateManager(event, newValue,e,"assembly_manager");
                          }}
                        />
                      }
                    </Grid>
                    <Grid item md={4} id="search">
                      {e.workshop_manager_pending &&
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={employees.map((d)=>{
                            if (d.name && d.code) return {label:d.name,id:d.code}
                            else return false
                          })}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label={e.workshop_manager_label} />}
                          onChange={(event, newValue) => {
                            disambiguateManager(event, newValue,e,"workshop_manager");
                          }}
                        />
                      }
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
          <DialogActions>
            {fullData2Store && fullData2Store.pending &&
              <Button onClick={download_txt}>Descargar errores</Button>
            }
            <Button onClick={sendFullDataSet}>Guardar</Button>
          </DialogActions>
        </Dialog>
      </Container>
  );

}
